import { Check } from "tabler-icons-react"

export const TestQuestion = (props: { title: string, variants: string[], answer: number, setAnswer: (val: number) => void }) => {

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
            <span style={{ marginBottom: "10px" }}>{props.title}</span>
            <div className="list-group">
                {props.variants.map((variant, index)=> {
                    return <TestItem index={index+1} title={variant} selected={props.answer==index?true:false} setSelected={() => {props.setAnswer(index)}} />
                })}
            </div>
        </div>
    )
}

export const TestItem = (props: { index: number, title: string, selected: boolean, setSelected: () => void }) => {

    return (
        <a href="#" onClick={(e)=>{e.preventDefault(); props.setSelected()}} className="list-group-item list-group-item-action d-flex justify-content-between" style={{backgroundColor: props.selected?"#dff7e9":""}}>
            <div className="li-wrapper d-flex justify-content-start align-items-center">
                <div className="avatar avatar-sm me-3">
                    <span className={props.selected?"avatar-initial rounded-circle bg-label-success":"avatar-initial rounded-circle bg-label-primary"}>{props.selected?<Check />:props.index}</span>
                </div>
                <div className="list-content">
                    <h6 className="mb-1">{props.title}</h6>
                </div>
            </div>
        </a>
    )
}