import { Question } from "../../domain/question/question";
import { QuestionActionTypes } from "../action-types";
import { QuestionActions } from "../actions";
import produce from 'immer';


interface QuestionState {
    loading: boolean | null;
    questions: Question[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: QuestionState = {
    loading: null,
    questions: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: QuestionState = initialState, action: QuestionActions) => {
    switch (action.type) {
        case QuestionActionTypes.QUESTION_REQUEST_SEND:
            state.loading = true;
            return state;
        case QuestionActionTypes.QUESTION_SUCCESS:
            state.loading = false;
            state.questions = action.payload;
            return state;
        case QuestionActionTypes.QUESTION_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case QuestionActionTypes.QUESTION_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case QuestionActionTypes.QUESTION_CREATE_SUCCESS:
            state.entityLoading = false
            state.questions!.push(action.payload)
            state.entityError = ""
            return state;
        case QuestionActionTypes.QUESTION_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case QuestionActionTypes.QUESTION_DELETE_SUCCESS:
            state.questions = state.questions!.filter((questions) => questions.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
