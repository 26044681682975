import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"

export const MentorList = () => {
    let { id } = useParams();

    const [form, setForm] = useState<{
    }>({
    })


    let useCases = useContext(UseCasesContext)

    const mentors = useTypedSelector(({ mentor }) => {
        return mentor
    })

    useEffect(()=>{
        useCases?.mentorUseCase.ReadMentors()
    }, [])



    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-xl-5 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список наставников /</span> Наставники</h4>
                        </div>


                        <div>
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/mentor/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить наставника</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card" style={{ background: "#fff", }}>
                            <div className="table-responsive text-nowrap col-xl-12">
                                <table className="table mt-1" style={{ marginBottom: mentors?.mentors?.length == 1 ? "200px" : 0 }}>
                                    <thead>
                                        <tr>
                                            <th>ФИО</th>
                                            <th>Дата создания</th>
                                            <th style={{width: "10px"}}>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            mentors?.mentors && mentors.mentors.map((mentor, index) => {
                                                return <TableRow key={mentor.Name() + index} mentor={mentor} last={mentors?.mentors!.length - 1 == index && mentors.mentors!.length > 1 ? true : false} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
