import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form, Field } from "../../../components/form"
import { Menu } from "../../../components/menu"

export class RangeQuestion {
    public from: number = 0;
    public to: number = 0;
}
export const QuestionAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();


    let useCases = useContext(UseCasesContext)

    const [form, setForm] = useState<{
        title: string,
        questionType: "" | "range" | "variants" | "comment",
        questionParams: RangeQuestion | string[] | null,
    }>({
        title: "",
        questionType: "",
        questionParams: null,
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if (form.title != "" && form.questionType.length > 0) {
            console.log(form)
            useCases?.questionUseCase.CreateQuestion(form.title!, form.questionType!, form.questionParams!, () => { navigate(-1) })
        }
    }

    const updateForm = (name: string, value: any) => {
        if (name.indexOf('.') !== -1 && name.indexOf('questionParams') !== -1) {
            
            if (form.questionParams instanceof RangeQuestion) {
                let newObjectValue = new RangeQuestion()
                newObjectValue.from = form["questionParams"].from
                newObjectValue.to = form["questionParams"].to
                let names = name.split(".")
                if (names[0] == "from") {
                    newObjectValue.from = value
                } else {
                    newObjectValue.to = value
                }

                console.log(newObjectValue)


                setForm({
                    ...form,
                    [names[0]]: newObjectValue,
                })
                return
            }
        } else {
            setForm({
                ...form,
                [name]: value,
            })
        }
    }



    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }

    const getQuestionForm = (): Field[] => {
        let fields: Field[] = [
            {
                name: "title",
                title: "Вопрос",
                placeholder: "Введите формулировку вопроса",
                value: form.title,
                type: FiledType.Text,
            },
            {
                name: "questionType",
                title: "Тип вопроса",
                placeholder: "Выберите тип вопроса",
                value: form.questionType,
                type: FiledType.Select,
                options: [
                    {
                        title: "Диапазон",
                        value: "range",
                    },
                    {
                        title: "Варианты ответов",
                        value: "variants",
                    },
                    {
                        title: "Текст",
                        value: "comment",
                    },
                ],
            },

        ]

        if (form.questionType == "range") {

            if (form.questionParams instanceof RangeQuestion) {
                fields.push(
                    {
                        name: "questionParams.from",
                        title: "Интервал с",
                        placeholder: "Введите начало диапозона",
                        value: form.questionParams!.from,
                        type: FiledType.Number,
                    },
                    {
                        name: "questionParams.to",
                        title: "По",
                        placeholder: "Введите конец диапозона",
                        value: form.questionParams!.to,
                        type: FiledType.Number,
                    })
            } 
        } else if (form.questionType == "variants") {
            fields.push(
                {
                    name: "questionParams",
                    title: "Список ответов",
                    placeholder: "Введите вариант ответа",
                    value: form.questionParams!,
                    type: FiledType.StringsList,
                },
            )
        }

        return fields
    }


    useEffect(() => {
        if (form.questionType == "range") {
            setForm({
                ...form,
                questionParams: new RangeQuestion(),
            })
        } else if (form.questionType == "comment") {
            setForm({
                ...form,
                questionParams: null,
            })
        } else if (form.questionType == "variants") {
            setForm({
                ...form,
                questionParams: [""],
            })
        }

    }, [form.questionType])



    useEffect(()=>{
        console.log(form)
    },[form])
    return (
        
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Вопросы /</span> Добавить вопрос</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление вопроса</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={getQuestionForm()}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
