import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Question } from "../../../../domain/question/question"
import { RangeQuestion } from "../../questions/add"
import { Modal } from "./modal"
import { QuestionAddModal } from "../../questions/add/question-add-modal"

export const AnketAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();


    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        stage: "study" | "internship" | "probation" | "",
        questions: string[],

    }>({
        stage: "",
        questions: [""],
    })

    const [questionOptions, setQuestionOptions] = useState<{ title: string, value: string }[]>([])
    const [showModal, setShowModal] = useState(false)


    const question = useTypedSelector(({ question }) => {
        return question
    })

    const stages = {
        "Обучение": "study",
        "Стажировка": "internship",
        "Испытательный срок": "probation",
    }

    const stagesOptions = [
        {
            title: "Обучение",
            value: "study"
        },
        {
            title: "Стажировка",
            value: "internship"
        },
        {
            title: "Испытательный срок",
            value: "probation"
        },
    ]

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (form.questions.length != 0) {
            if (id) {
                useCases?.anketUseCase.UpdateAnketQuestions(id, form.questions!, () => { navigate(-1) })
            } else {
                if (form.stage != "") {
                    useCases?.anketUseCase.CreateAnket(form.questions!, form.stage!, () => { navigate(-1) })
                }
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }


    useEffect(() => {
        useCases?.questionUseCase.ReadQuestions()
    }, [])

    const getCommentToQuestion = (question: Question): string => {
        switch (question.QuestionType()) {
            case "comment":
                return "комментарий"
            case "range":
                try {
                    let params: { from: number, to: number } = question.QuestionParams()! as { from: number, to: number };
                    return "диапазон от " + params!.from + " до " + params.to!
                } catch (e) {
                    return "диапазон"
                }
            case "variants":
                let params: string[] = question.QuestionParams()! as string[];
                return "тест " + (params.length) + " варианта х-ов)"
        }
        return ""
    }
    
    useEffect(() => {
        let newQuestions: { title: string, value: string }[] = []
        question?.questions?.map((question) => {
            newQuestions.push({
                title: question.Title() + " [" + (getCommentToQuestion(question)) + "]",
                value: question.Id(),
            })
        })
        setQuestionOptions([...newQuestions])
    }, [question?.questions])

    const readAnketById = async (id: string) => {
        let response = await useCases?.anketUseCase.ReadAnketById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                ...form,
                questions: response?.Questions()!,
            })
        }

        
    } 

    useEffect(()=>{
        if (id) {
            readAnketById(id)
        }
    },[id])



    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Анкета /</span> Редактировать анкету</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Редактирование анкеты</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={id ? [

                                        {
                                            name: "questions",
                                            title: "Вопрос",
                                            placeholder: "Выберите вопрос",
                                            value: form.questions,
                                            type: FiledType.SelectList,
                                            options: questionOptions,
                                            callbackAdd: () => { setShowModal(true) },
                                        },
                                    ] : [
                                        {
                                            name: "stage",
                                            title: "Этап",
                                            placeholder: "Укажите этап на коотором нужно требовать заполнение анкеты",
                                            value: form.stage,
                                            type: FiledType.Select,
                                            options: stagesOptions,
                                        },
                                        {
                                            name: "questions",
                                            title: "Вопрос",
                                            placeholder: "Выберите вопрос",
                                            value: form.questions,
                                            type: FiledType.SelectList,
                                            options: questionOptions,
                                            callbackAdd: () => { setShowModal(true) },
                                        },
                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>
                            {showModal ? <QuestionAddModal callback={() => { setShowModal(false) }} successCallback={() => { useCases?.questionUseCase.ReadQuestions(); setShowModal(false); }} /> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
