import { Dispatch } from "react"
import { ReviewActionTypes } from "../action-types";
import { ReviewActions } from "../actions"
import { Review } from "../../domain/review/review";

export const SendReviewListRequest = () => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_REQUEST_SEND,
          });
    }
}

export const ReviewListSuccess = (list: Review[]) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_SUCCESS,
            payload: list,
        });
    }
}

export const ReviewListError = (error:string) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_ERROR,
            payload: error,
        });
    }
}

export const ReviewCreateRequest = () => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_CREATE_REQUEST,
          });
    }
}

export const ReviewCreateSuccess = (review: Review) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_CREATE_SUCCESS,
            payload: review,
          });
    }
}

export const ReviewCreateError = (message: string) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_CREATE_ERROR,
            payload: message,
          });
    }
}


export const ReviewUpdateRequest = () => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_UPDATE_REQUEST,
          });
    }
}

export const ReviewUpdateSuccess = (category: Review) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const ReviewUpdateError = (message: string) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const ReviewDeleteRequest = () => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_DELETE_REQUEST,
          });
    }
}

export const ReviewDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const ReviewDeleteError = (message: string) => {
    return async (dispatch: Dispatch<ReviewActions>) => {
        dispatch({
            type: ReviewActionTypes.REVIEW_DELETE_ERROR,
            payload: message,
          });
    }
}