import { QuestionsRepository } from "../../repository/api/question/question";
import { actionCreators } from "../../state";

export class QuestionUseCases {
    private api: QuestionsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: QuestionsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadQuestions = async () => {
        this.stateActions.SendQuestionListRequest()
        let response = await this.api.ReadQuestions()
        if (response instanceof Error) {
            this.stateActions.QuestionListError(response.message)
            return
        } else {
            this.stateActions.QuestionListSuccess(response)
        }
    }

    public DeleteQuestion = async (id: string) => {
        this.stateActions.QuestionDeleteRequest()
        let response = await this.api.DeleteQuestionById(id)
        if (response instanceof Error) {
            this.stateActions.QuestionDeleteError(response.message)
            return
        } else {
            this.stateActions.QuestionDeleteSuccess(id)
        }
    }

    public ReadQuestionById = async (id: UniqueId) => {
        let response = await this.api.ReadQuestionById(id)
        return response
    }

    public CreateQuestion = async (title: string, questionType: string, questionParams: Object, callback?: ()=>void) => {
        this.stateActions.QuestionCreateRequest()
        let response = await this.api.CreateQuestion(title, questionType, questionParams)
        if (response instanceof Error) {
            this.stateActions.QuestionCreateError(response.message)
            return
        } else {
            this.stateActions.QuestionCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }



    public UpdateQuestion = async (id: string, title: string, questionType: string, questionParams: Object, callback?: ()=>void) => {
        this.stateActions.QuestionUpdateRequest()
        let response = await this.api.UpdateQuestion(id, title, questionType, questionParams)
        if (response instanceof Error) {
            this.stateActions.QuestionUpdateError(response.message)
            return
        } else {
            this.stateActions.QuestionUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}