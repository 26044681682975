import { Dispatch } from "react"
import { QuestionActionTypes } from "../action-types";
import { QuestionActions } from "../actions"
import { Question } from "../../domain/question/question";

export const SendQuestionListRequest = () => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_REQUEST_SEND,
          });
    }
}

export const QuestionListSuccess = (list: Question[]) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_SUCCESS,
            payload: list,
        });
    }
}

export const QuestionListError = (error:string) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_ERROR,
            payload: error,
        });
    }
}

export const QuestionCreateRequest = () => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_CREATE_REQUEST,
          });
    }
}

export const QuestionCreateSuccess = (question: Question) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_CREATE_SUCCESS,
            payload: question,
          });
    }
}

export const QuestionCreateError = (message: string) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_CREATE_ERROR,
            payload: message,
          });
    }
}


export const QuestionUpdateRequest = () => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_UPDATE_REQUEST,
          });
    }
}

export const QuestionUpdateSuccess = (category: Question) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const QuestionUpdateError = (message: string) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const QuestionDeleteRequest = () => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_DELETE_REQUEST,
          });
    }
}

export const QuestionDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const QuestionDeleteError = (message: string) => {
    return async (dispatch: Dispatch<QuestionActions>) => {
        dispatch({
            type: QuestionActionTypes.QUESTION_DELETE_ERROR,
            payload: message,
          });
    }
}