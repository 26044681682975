export class Anket {
     
    private id: string; 
    private stage: string;
    private questions: string[]; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, stage: string, questions: string[], createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.stage = stage;
        this.questions = questions;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }

    public Stage = (): string => {
        return this.stage
    }

    public Questions = (): string[] => {
        return this.questions
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}