import { Dispatch } from "react"
import { InternActionTypes } from "../action-types";
import { InternActions } from "../actions"
import { Intern } from "../../domain/intern/intern";

export const SendInternListRequest = () => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_REQUEST_SEND,
          });
    }
}

export const InternListSuccess = (list: Intern[]) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_SUCCESS,
            payload: list,
        });
    }
}

export const InternListError = (error:string) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_ERROR,
            payload: error,
        });
    }
}

export const InternCreateRequest = () => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_CREATE_REQUEST,
          });
    }
}

export const InternCreateSuccess = (intern: Intern) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_CREATE_SUCCESS,
            payload: intern,
          });
    }
}

export const InternCreateError = (message: string) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_CREATE_ERROR,
            payload: message,
          });
    }
}


export const InternUpdateRequest = () => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_UPDATE_REQUEST,
          });
    }
}

export const InternUpdateSuccess = (category: Intern) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const InternUpdateError = (message: string) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const InternDeleteRequest = () => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_DELETE_REQUEST,
          });
    }
}

export const InternDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const InternDeleteError = (message: string) => {
    return async (dispatch: Dispatch<InternActions>) => {
        dispatch({
            type: InternActionTypes.INTERN_DELETE_ERROR,
            payload: message,
          });
    }
}