import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { MentorMentorWithId } from "../../../../repository/api/generated"

export const MentorAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();


    let useCases = useContext(UseCasesContext)
    const [amoUserIds, setAmoUserIds] = useState<{ title: string, value: number }[]>([])
    const [amoStudyIds, setAmoStudyIds] = useState<{ title: string, value: number }[]>([])
    const [amoStageIds, setAmoStageIds] = useState<{ title: string, value: number }[]>([])
    const [amoIsIds, setAmoIsIds] = useState<{ title: string, value: number }[]>([])
    const [updateNames, setUpdateNames] = useState(true)
    const [form, setForm] = useState<{
        name: string,
        amoUserId: number,
        stageAmoId: number,
        studyAmoId: number,
        isAmoId: number
    }>({
        name: "",
        amoUserId: 0,
        stageAmoId: 0,
        studyAmoId: 0,
        isAmoId: 0,
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()


        if (form.name != "") {
            if (id) {
                useCases?.mentorUseCase.UpdateMentor(id, form.name!, form.amoUserId, form.stageAmoId, form.studyAmoId, form.isAmoId, () => { navigate(-1) })
            } else {
                useCases?.mentorUseCase.CreateMentor(form.name!, form.amoUserId, form.stageAmoId, form.studyAmoId, form.isAmoId, () => { navigate(-1) })
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readAmoMentorFields = async () => {
        let response = await useCases?.mentorUseCase.ReadMentorAmoFields()
        if (response instanceof Error) {

        } else {
            setAmoUserIds(toFieldValues(response!["users"]))
            setAmoStudyIds(toFieldValues(response!["study"]))
            setAmoStageIds(toFieldValues(response!["stage"]))
            setAmoIsIds(toFieldValues(response!["is"]))
        }
    }

    const toFieldValues = (list: MentorMentorWithId[]): { title: string, value: number }[] => {
        let values: { title: string, value: number }[] = []

        list.forEach((item) => {
            values.push({
                title: item.name! + " (" + item.id! + ")",
                value: item.id!
            })
        })
        return values
    }

    const readMentorById = async (id: string) => {
        setUpdateNames(false)
        await readAmoMentorFields()
        let response = await useCases?.mentorUseCase.ReadMentorById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                ...form,
                name: response?.Name()!,
                amoUserId: response?.AmoUserId()!,
                stageAmoId: response?.StageAmoId()!,
                studyAmoId: response?.StudyAmoId()!,
                isAmoId: response?.IsAmoId()!,
            })
        }


    }


    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }


    useEffect(() => {
        if (id) {
            readMentorById(id)
        } else {
            readAmoMentorFields()
        }
    }, [])

    useEffect(() => {
        if (updateNames) {
            if (form.name === "") {
                setForm({
                    ...form,
                    amoUserId: 0,
                    studyAmoId: 0,
                    stageAmoId: 0,
                    isAmoId: 0,
                })
                return
            }
            let formNew = { ...form }

            let amoUserId = amoUserIds.filter((user) => user.title.indexOf(form.name) === 0)[0]
            if (amoUserId) {
                formNew.amoUserId = amoUserId.value
            } else {
                formNew.amoUserId = 0
            }



            let studyAmoId = amoStudyIds.filter((user) => user.title.indexOf(form.name) === 0)[0]
            if (studyAmoId) {
                formNew.studyAmoId = studyAmoId.value
            } else {
                formNew.studyAmoId = 0
            }

            let stageAmoId = amoStageIds.filter((user) => user.title.indexOf(form.name) === 0)[0]
            if (stageAmoId) {
                formNew.stageAmoId = stageAmoId.value
            } else {
                formNew.stageAmoId = 0
            }

            let isAmoId = amoIsIds.filter((user) => user.title.indexOf(form.name) === 0)[0]
            if (isAmoId) {
                formNew.isAmoId = isAmoId.value
            } else {
                formNew.isAmoId = 0
            }


            setForm({ ...formNew })
        }
    }, [form.name])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Наставник /</span> {id ? "Редактировать" : "Добавить"} наставника</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{id ? "Редактирование" : "Добавление"} наставника</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "name",
                                            title: "ФИО наставника",
                                            placeholder: "Укажите ФИО наставника",
                                            value: form.name,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "amoUserId",
                                            title: "Пользователь в амо срм",
                                            placeholder: "Укажите пользователя в амо срм",
                                            value: form.amoUserId,
                                            type: FiledType.SelectInt,
                                            options: amoUserIds,
                                        },
                                        {
                                            name: "studyAmoId",
                                            title: "Этап Обучение (если наставник есть на этом этапе)",
                                            placeholder: "Укажите id пользователя на этапе \"Обучение\"",
                                            value: form.studyAmoId,
                                            type: FiledType.SelectInt,
                                            options: amoStudyIds,
                                        },
                                        {
                                            name: "stageAmoId",
                                            title: "Этап Стажировка (если наставник есть на этом этапе)",
                                            placeholder: "Укажите id пользователя на этапе \"Стажировка\"",
                                            value: form.stageAmoId,
                                            type: FiledType.SelectInt,
                                            options: amoStageIds,
                                        },
                                        {
                                            name: "isAmoId",
                                            title: "Этап Испытательный срок (если наставник есть на этом этапе)",
                                            placeholder: "Укажите id пользователя на этапе \"Испытательный срок\"",
                                            value: form.isAmoId,
                                            type: FiledType.SelectInt,
                                            options: amoIsIds,
                                        },
                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
