import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { MentorMentorWithId, ReviewReviewReportForAnketVersion } from "../../../../repository/api/generated"
import { Question } from "../../../../domain/question/question"

export const ReviewReport = () => {
    let { id } = useParams();

    const firstDayPreviousMonth = () => {
        var d = new Date();
        d.setDate(0); // set to last day of previous month
        d.setDate(1); // set to the first day of that month
        return d;
    }

    const [amoStudyIds, setAmoStudyIds] = useState<{ title: string, value: number }[]>([])
    const [amoStageIds, setAmoStageIds] = useState<{ title: string, value: number }[]>([])
    const [amoIsIds, setAmoIsIds] = useState<{ title: string, value: number }[]>([])
    const [rgsIds, setRgsIds] = useState<{ title: string, value: number }[]>([])

    const [report, setReport] = useState<ReviewReviewReportForAnketVersion[]>([])
    const [currentReport, setCurrentReport] = useState<ReviewReviewReportForAnketVersion | null>()
    const [questionsList, setQuestionsList] = useState<Map<string, Question> | null>(null)
    const [filter, setFilter] = useState<{ fio: string, isMentor: number, probationMentor: number, studyMentor: number, headOfGroup: number, isFired: null | boolean, }>(
        {
            fio: "",
            isMentor: 0,
            probationMentor: 0,
            studyMentor: 0,
            headOfGroup: 0,
            isFired: null,
        }
    )

    const [form, setForm] = useState<{
        from: Date,
        to: Date,
    }>({
        from: firstDayPreviousMonth(),
        to: new Date(),
    })

    let useCases = useContext(UseCasesContext)

    const readReport = async () => {
        let response = await useCases?.reviewUseCase.ReadReport(form.from, form.to, filter)
        if (response instanceof Error) {

        } else {
            setReport(response!)
        }
    }

    const readAmoMentorFields = async () => {
        let response = await useCases?.mentorUseCase.ReadMentorAmoFields()
        if (response instanceof Error) {

        } else {
            setAmoStudyIds(toFieldValues(response!["study"]))
            setAmoStageIds(toFieldValues(response!["stage"]))
            setAmoIsIds(toFieldValues(response!["is"]))
            setRgsIds(toFieldValues(response!["rg"]))
        }
    }

    const toFieldValues = (list: MentorMentorWithId[]): { title: string, value: number }[] => {
        let values: { title: string, value: number }[] = []

        list.forEach((item) => {
            values.push({
                title: item.name! + " (" + item.id! + ")",
                value: item.id!
            })
        })
        return values
    }

    useEffect(() => {
        if (form.from && form.to) {
            readReport()
        }
    }, [form, filter])

    useEffect(() => {
        if (report.length > 0) {
            if (currentReport == null) {
                setCurrentReport(report[0])
            } else {
                let isSameReportExists = false

                for (let index = 0; index < report.length; index++) {
                    const item = report[index];
                    if (item.anket_version_date == currentReport.anket_version_date) {
                        setCurrentReport(item)
                        isSameReportExists = true
                        break;
                    }

                }

                if (!isSameReportExists) {
                    setCurrentReport(report[0])
                }
            }
        }
    }, [report])

    const updateCurrentReport = (input: string) => {
        let date = input
        for (let index = 0; index < report.length; index++) {
            const item = report[index];
            if (item.anket_version_date! == date) {
                setCurrentReport(item)

            }

        }
    }

    const questions = useTypedSelector(({ question }) => {
        return question
    })

    useEffect(() => {
        useCases?.questionUseCase.ReadQuestions()
        readAmoMentorFields()
    }, [])

    useEffect(() => {
        let questionMap = new Map<string, Question>()
        questions?.questions?.forEach(question => {
            questionMap.set(question.Id(), question)
        });
    }, [questions])

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + "-" + dd;
    }

    const formatDateWithTime = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + "." + mm + "." + yyyy + " " + day.getHours() + ":" + day.getMinutes();
    }




    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-xl-5 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Отчет по стажерам /</span> Отчет</h4>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="col mb-3">
                                            <label htmlFor="nameBasic" className="form-label">Дата с</label>
                                            <input type="date" value={formatDate(form.from)} onChange={(e) => { setForm({ ...form, "from": new Date(e.target.value) }) }} id="nameBasic" className="form-control" placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="col mb-3">
                                            <label htmlFor="nameBasic" className="form-label">По</label>
                                            <input type="date" value={formatDate(form.to)} onChange={(e) => { setForm({ ...form, "to": new Date(e.target.value) }) }} id="nameBasic" className="form-control" placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4"></div>

                                    <div className="col-sm-4">
                                        <div className="col">
                                            <label htmlFor="fio_search" className="form-label">ФИО Стажера</label>
                                            <input type="text" value={filter.fio} onChange={(e) => { setFilter({ ...filter, "fio": e.target.value }) }} id="fio_search" className="form-control" placeholder="Введите имя" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="col">
                                            <label htmlFor="fio_search" className="form-label" >Оценивающий Обучение</label>
                                            <select className="form-select" onChange={(e) => { setFilter({ ...filter, "studyMentor": parseInt(e.target.value) })}} value={filter.studyMentor} >
                                                <option value="0">Все наставники</option>
                                                {amoStudyIds.map((mentor) => {
                                                    return <option value={mentor.value}>{mentor.title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="col">
                                            <label htmlFor="fio_search" className="form-label" >Оценивающий Стажировку</label>
                                            <select className="form-select" onChange={(e) => { setFilter({ ...filter, "probationMentor": parseInt(e.target.value) })}} value={filter.probationMentor} >
                                                <option value="0">Все наставники</option>
                                                {amoStageIds.map((mentor) => {
                                                    return <option value={mentor.value}>{mentor.title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="col">
                                            <label htmlFor="fio_search" className="form-label" >Оценивающий ИС</label>
                                            <select className="form-select" onChange={(e) => { setFilter({ ...filter, "isMentor": parseInt(e.target.value) })}} value={filter.isMentor} >
                                                <option value="0">Все наставники</option>
                                                {amoIsIds.map((mentor) => {
                                                    return <option value={mentor.value}>{mentor.title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="col">
                                            <label htmlFor="fio_search" className="form-label" >Старший оператор</label>
                                            <select className="form-select" onChange={(e) => { setFilter({ ...filter, "headOfGroup": parseInt(e.target.value) })}} value={filter.headOfGroup} >
                                                <option value="0">Все наставники</option>
                                                {rgsIds.map((mentor) => {
                                                    return <option value={mentor.value}>{mentor.title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="col">
                                            <label htmlFor="fio_search" className="form-label" >Уволен/Работает</label>
                                            <select className="form-select" onChange={(e) => { setFilter({ ...filter, "isFired": e.target.value == "" ? null: e.target.value === "true"? true: false })}} value={filter.isFired==null?"":filter.isFired.toString()} >
                                                <option value="">Все</option>
                                                <option value="true">Уволен</option>
                                                <option value="false">Работает</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {currentReport ? <div className="card" style={{ background: "#fff", }}>
                            <div className="mb-3 row mt-3">
                                <label className="col-md-2 col-form-label"><b>Выберите версию анкеты</b></label>
                                <div className="col-md-4">
                                    <select className="form-select" value={currentReport.anket_version_date!} onChange={(e) => updateCurrentReport(e.target.value)}>
                                        {report.map((report) => {
                                            return <option value={report.anket_version_date!}>{formatDateWithTime(new Date(report.anket_version_date!))}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="table-responsive text-nowrap col-xl-12">
                                <table className="table table-bordered table-hover mt-1" >
                                    <thead>
                                        <tr>
                                            <th className="text-center align-middle" rowSpan={2}>ФИ</th>
                                            <th className="text-center align-middle" rowSpan={2}>Возраст</th>
                                            <th className="text-center align-middle" rowSpan={2}>Дата <br /> обучения</th>
                                            <th className="text-center align-middle" rowSpan={2}>Дата <br /> стажировки</th>
                                            <th className="text-center align-middle" rowSpan={2}>Дата <br /> ИС</th>
                                            <th className="text-center align-middle" rowSpan={2}>Оценивающий <br /> обучение</th>
                                            <th className="text-center align-middle" rowSpan={2}>Оценивающий <br /> стажировку</th>
                                            <th className="text-center align-middle" rowSpan={2}>Оценивающий <br /> ИС</th>
                                            <th className="text-center" colSpan={currentReport.answer_question_ids_order!["study"].length}>Обучение</th>
                                            <th className="text-center" colSpan={currentReport.answer_question_ids_order!["internship"].length+1}>Стажировка</th>
                                            <th className="text-center" colSpan={currentReport.answer_question_ids_order!["probation"].length+1}>Испытательный срок</th>
                                            <th className="text-center" colSpan={2}>Итог</th>
                                            <th className="text-center align-middle" rowSpan={2}>РГ</th>
                                            <th className="text-center" colSpan={3}>Увольнение</th>
                                        </tr>
                                        <tr>
                                            {currentReport.answer_question_ids_order!["study"].map((question, index) => { return <th>{question.title}</th> })}
                                            {currentReport.answer_question_ids_order!["internship"].map((question, index) => { return <th>{question.title}</th> })}
                                            <th>РОМИ</th>
                                            {currentReport.answer_question_ids_order!["probation"].map((question, index) => { return <th>{question.title}</th> })}
                                            <th>РОМИ</th>
                                            <th>Ср. по компетенциям</th>
                                            <th>Динамика РОМИ</th>
                                            <th>Дата увольнения</th>
                                            <th>Этап увольнения</th>
                                            <th>Причина</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            currentReport.intern?.map((internItem, index) => {
                                                return <TableRow reviewItem={internItem} questionsMap={currentReport.answer_question_ids_order!} last={currentReport?.intern!.length - 1 == index && currentReport?.intern!.length > 1 ? true : false} />
                                            })
                                        }
                                        {
                                            // mentors?.mentors && mentors.mentors.map((mentor, index) => {
                                            //     return <TableRow key={mentor.Name() + index} mentor={mentor} last={mentors?.mentors!.length - 1 == index && mentors.mentors!.length > 1 ? true : false} />
                                            // })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div> : <></>}

                    </div>
                </div>
            </div>
        </div>
    )

}
