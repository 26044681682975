import { Logout, Stars, School, ChartInfographic, Users } from 'tabler-icons-react';

export const Items = [

    {
        title: "Отчет",
        icon: <ChartInfographic size={24} className="menu-icon"  />,
        href: "/review/report",
    },

    {
        title: "Распределение стажеров",
        icon: <Users size={24} className="menu-icon"  />,
        href: "/interns",
    },

    {
        title: "Наставники",
        icon: <School size={24} className="menu-icon"  />,
        href: "/mentor",
    },

    {
        title: "Анкеты",
        icon: <Stars size={24} className="menu-icon"  />,
        href: "/anket",
    },
    
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },

]