export class Intern {
     
    private id: string; 
    private amoLeadId: number; 
    private name: string; 
    private age: number; 
    private city: string; 
    private gender: string; 
    private studyDate: Date; 
    private internshipDate: Date;
    private probationDate: Date;
    private stateDate: Date;
    private assignedToRg: number;
    private stageMetriks: StageMetriks[];
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, amoLeadId: number, name: string, age: number, city: string, gender: string, studyDate: Date,internshipDate: Date,probationDate: Date,stateDate: Date,assignedToRg: number, stageMetriks: StageMetriks[], createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.amoLeadId = amoLeadId;
        this.name = name;
        this.age = age;
        this.city = city;
        this.gender = gender;
        this.studyDate = studyDate;
        this.internshipDate = internshipDate;

        this.probationDate = probationDate;
        this.stateDate = stateDate;
        this.assignedToRg = assignedToRg;
        
        this.stageMetriks = stageMetriks;

        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public AmoLeadId = (): number => {
        return this.amoLeadId
    }
    public Name = (): string => {
        return this.name
    }
    public Age = (): number => {
        return this.age
    }
    public City = (): string => {
        return this.city
    }
    public Gender = (): string => {
        return this.gender
    }


    public StudyDate = (): Date => {
        return this.studyDate
    }

    public InternshipDate = (): Date => {
        return this.internshipDate
    }

    public ProbationDate = (): Date => {
        return this.probationDate
    }

    public StateDate = (): Date => {
        return this.stateDate
    }

    public AssignedToRg = (): number => {
        return this.assignedToRg
    }

    public StageMetriks = (): StageMetriks[] => {
        return this.stageMetriks
    }


    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}

export class StageMetriks {
    private stage: string; 
    private profit: number;
    private romi: number; 

    constructor(stage : string, profit: number, romi: number) {
        this.stage = stage;
        this.profit = profit;
        this.romi = romi;
    }

    public Stage = (): string => {
        return this.stage
    }

    public Profit = (): number => {
        return this.profit
    }
    
    public Romi = (): number => {
        return this.romi
    }
}