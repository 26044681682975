import { useState } from "react"
import { Check, Circle } from "tabler-icons-react"

export const CommentQuestion = (props: { title: string, answer: string, setAnswer: (val: string) => void }) => {

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
            <label htmlFor={props.title} className="form-label">{props.title}</label>
            <textarea
                className="form-control"
                name={props.title}
                placeholder={"Заполните поле"}
                onChange={(e) =>{props.setAnswer(e.target.value)}}
                style={{minHeight:"100px"}}
                value={props.answer}
                
            />
        </div>
    )
}