import { InternsRepository } from "../../repository/api/intern/intern";
import { actionCreators } from "../../state";

export class InternUseCases {
    private api: InternsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: InternsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }


    public FireIntern = async (id: number) => {
        let response = await this.api.SetInternIsFired(id)
        
        return response
    }

    public ReadInternsForAssign = async () => {
        this.stateActions.SendInternListRequest()
        let response = await this.api.ReadInternsForAssign()
        if (response instanceof Error) {
            this.stateActions.InternListError(response.message)
            return
        } else {
            this.stateActions.InternListSuccess(response)
        }
    }
    
    public AssignInternToRg = async (internId: UniqueId, rgAmoId: number, callback: () => void) => {
        let response = await this.api.AssignInternToRg(internId, rgAmoId)
        if (response instanceof Error) {
            this.stateActions.InternUpdateError(response.message)
            return
        } else {
            
            this.stateActions.InternDeleteSuccess(response.Id()) // remove from interns for assign list
            callback()
        }
    }

    public ReadInternWithReviewes = async (internId: UniqueId) => {
        let response = await this.api.ReadInternWithReviewes(internId)
        return response
    }

    public ReadRgList = async () => {
        let response = await this.api.ReadRgList()
        return response
    }

}