import { Dispatch } from "react"
import { AnketActionTypes } from "../action-types";
import { AnketActions } from "../actions"
import { Anket } from "../../domain/anket/anket";

export const SendAnketListRequest = () => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_REQUEST_SEND,
          });
    }
}

export const AnketListSuccess = (list: Anket[]) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_SUCCESS,
            payload: list,
        });
    }
}

export const AnketListError = (error:string) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_ERROR,
            payload: error,
        });
    }
}

export const AnketCreateRequest = () => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_CREATE_REQUEST,
          });
    }
}

export const AnketCreateSuccess = (anket: Anket) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_CREATE_SUCCESS,
            payload: anket,
          });
    }
}

export const AnketCreateError = (message: string) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_CREATE_ERROR,
            payload: message,
          });
    }
}


export const AnketUpdateRequest = () => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_UPDATE_REQUEST,
          });
    }
}

export const AnketUpdateSuccess = (category: Anket) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const AnketUpdateError = (message: string) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const AnketDeleteRequest = () => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_DELETE_REQUEST,
          });
    }
}

export const AnketDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const AnketDeleteError = (message: string) => {
    return async (dispatch: Dispatch<AnketActions>) => {
        dispatch({
            type: AnketActionTypes.ANKET_DELETE_ERROR,
            payload: message,
          });
    }
}