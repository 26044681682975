import axios from "axios";
import { Anket } from "../../../domain/anket/anket";
import { Configuration, AnketApi, AnketAnketResponse, DomainStage } from "../generated";
import { AnketRepositoryInterface } from "../../../useCase/anket/adapters/repository/interface";

export class AnketsRepository implements AnketRepositoryInterface {
    private service: AnketApi;

    constructor() {
        this.service = new AnketApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadAnkets(): Promise<Anket[] | Error> {
        try {
            let response = await this.service.apiAnketGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let ankets : Anket[] = []
                response.data?.map((anketApi) => {
                    let anket = this.toDomainAnket(anketApi);
                    ankets.push(anket)
                })
                return ankets
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async PublishNewAnketVersion(): Promise<string | Error> {
        try {
            let response = await this.service.apiAnketPublishPost({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return ""
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateAnket(questions: UniqueId[], stage: string): Promise<Anket | Error> {
        try {
            let stageConverted: DomainStage; 
            
            switch (stage) {
                case DomainStage.Study:
                    stageConverted = DomainStage.Study;
                    break;
                case DomainStage.Probation:
                    stageConverted = DomainStage.Probation;
                    break;
                case DomainStage.Internship:
                    stageConverted = DomainStage.Internship;
                    break;
                default:
                    return Error("error converting stage");
            }

            let response = await this.service.apiAnketPost({questions: questions, stage: stageConverted}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                let anket = this.toDomainAnket(response.data);
                return anket
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateAnketQuestions(id: UniqueId, questions: UniqueId[]): Promise<Anket | Error> {
        try {

            let response = await this.service.apiAnketQuestionsIdPut(id, {questions: questions}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let anket = this.toDomainAnket(response.data);
                return anket
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadAnketById(id: UniqueId): Promise<Anket | Error> {
        try {

            let response = await this.service.apiAnketIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let anket = this.toDomainAnket(response.data);
                return anket
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeleteAnketById(id: UniqueId): Promise<string | Error> {
        try {

            let response = await this.service.apiAnketIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return id
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    

    toDomainAnket(anket: AnketAnketResponse): Anket {
        return new Anket(anket.id!, anket.stage!, anket.questions!, new Date(anket.created_at!), new Date(anket.modified_at!));
    }
}