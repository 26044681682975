export enum MentorActionTypes {
    MENTOR_REQUEST_SEND = 'mentor_request_send',
    MENTOR_ERROR = 'mentor_error',
    MENTOR_SUCCESS = 'mentor_success',
    
    MENTOR_CREATE_REQUEST = 'mentor_create_request',
    MENTOR_CREATE_SUCCESS = 'mentor_create_success',
    MENTOR_CREATE_ERROR = 'mentor_create_error',

    MENTOR_DELETE_REQUEST = 'mentor_delete_request',
    MENTOR_DELETE_SUCCESS = 'mentor_delete_success',
    MENTOR_DELETE_ERROR = 'mentor_delete_error',

    MENTOR_UPDATE_REQUEST = 'mentor_update_request',
    MENTOR_UPDATE_SUCCESS = 'mentor_update_success',
    MENTOR_UPDATE_ERROR = 'mentor_update_error',
}