import { useContext, useState } from "react";
import { Copy, DotsVertical, Edit, Phone, Trash, WorldWww } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { ReviewAnswer, ReviewQuestionTitleId, ReviewReviewReportItem } from "../../../../repository/api/generated";

export const TableRow = (props: { reviewItem: ReviewReviewReportItem, questionsMap: { [key: string]: Array<ReviewQuestionTitleId>; }, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)
    

    const formatDate = (day: Date) => {
        if (day.getFullYear() < 100) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }

    const getAnswerForQuestion = (type: string, questionId: string) => {
        let answers: ReviewAnswer[] | undefined = undefined
        switch (type) {
            case "internship":
                answers = props.reviewItem.internship_answers
                break
            case "probation":
                answers = props.reviewItem.probation_answers
                break
            case "study":
                answers = props.reviewItem.study_answers
                break
        }

        if (!answers) {
            return ""
        }

        for (let index = 0; index < answers.length; index++) {
            const element = answers[index];
            if (questionId == element.question_id) {
                return element.question_answer
            }
        }
        
        
    }




    return (<tr >
        <td>{props.reviewItem.intern}</td>
        <td>{props.reviewItem.age}</td>
        <td>{formatDate(new Date(props.reviewItem.study_date!))}</td>
        <td>{formatDate(new Date(props.reviewItem.internship_date!))}</td>
        <td>{formatDate(new Date(props.reviewItem.probation_date!))}</td>
        <td>{props.reviewItem.study_mentor_name!}</td>
        <td>{props.reviewItem.probation_mentor_name!}</td>
        <td>{props.reviewItem.internship_mentor_name!}</td>
        {props.questionsMap["study"].map((question)=>{
            return <td>{getAnswerForQuestion("study", question.id!)}</td>
        })}
        {props.questionsMap["internship"].map((question)=>{
            return <td>{getAnswerForQuestion("internship", question.id!)}</td>
        })}
        <td>{props.reviewItem.internship_romi}</td>
        {props.questionsMap["probation"].map((question)=>{
            return <td>{getAnswerForQuestion("probation", question.id!)}</td>
        })}
        <td>{props.reviewItem.probation_romi}</td>
        <td>{props.reviewItem!.range_sum! / props.reviewItem!.range_count!}</td>
        <td>{props.reviewItem.romi_dynamic}</td>
        <td>{props.reviewItem.head_of_group}</td>
        <td>{formatDate(new Date(props.reviewItem.fired?.date!))}</td>
        <td>{props.reviewItem.fired?.stage}</td>
        <td>{props.reviewItem.fired?.cause}</td>
    </tr>)
}
