export enum InternActionTypes {
    INTERN_REQUEST_SEND = 'intern_request_send',
    INTERN_ERROR = 'intern_error',
    INTERN_SUCCESS = 'intern_success',
    
    INTERN_CREATE_REQUEST = 'intern_create_request',
    INTERN_CREATE_SUCCESS = 'intern_create_success',
    INTERN_CREATE_ERROR = 'intern_create_error',

    INTERN_DELETE_REQUEST = 'intern_delete_request',
    INTERN_DELETE_SUCCESS = 'intern_delete_success',
    INTERN_DELETE_ERROR = 'intern_delete_error',

    INTERN_UPDATE_REQUEST = 'intern_update_request',
    INTERN_UPDATE_SUCCESS = 'intern_update_success',
    INTERN_UPDATE_ERROR = 'intern_update_error',
}