export class Mentor {
     
    private id: string; 
    private name: string; 
    private amoUserId: number; 
    private studyAmoId: number; 
    private stageAmoId: number; 
    private isAmoId: number; 
    private createdAt: Date; 
    private modifeidAt: Date;

    constructor( id: string, name: string, amoUserId: number, studyAmoId: number, stageAmoId: number, isAmoId: number, createdAt: Date, modifeidAt: Date,) {
        this.id = id;
        this.name = name;
        this.amoUserId = amoUserId;
        this.studyAmoId = studyAmoId;
        this.stageAmoId = stageAmoId;
        this.isAmoId = isAmoId;
        this.createdAt = createdAt;
        this.modifeidAt = modifeidAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public AmoUserId = (): number => {
        return this.amoUserId
    }
    public StudyAmoId = (): number => {
        return this.studyAmoId
    }
    public StageAmoId = (): number => {
        return this.stageAmoId
    }
    public IsAmoId = (): number => {
        return this.isAmoId
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifeidAt = (): Date => {
        return this.modifeidAt
    }
}