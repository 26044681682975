import { MentorsRepository } from "../../repository/api/mentor/mentor";
import { actionCreators } from "../../state";

export class MentorUseCases {
    private api: MentorsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: MentorsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadMentors = async () => {
        this.stateActions.SendMentorListRequest()
        let response = await this.api.ReadMentors()
        if (response instanceof Error) {
            this.stateActions.MentorListError(response.message)
            return
        } else {
            this.stateActions.MentorListSuccess(response)
        }
    }

    public DeleteMentor = async (id: string) => {
        this.stateActions.MentorDeleteRequest()
        let response = await this.api.DeleteMentorById(id)
        if (response instanceof Error) {
            this.stateActions.MentorDeleteError(response.message)
            return
        } else {
            this.stateActions.MentorDeleteSuccess(id)
        }
    }

    public ReadMentorById = async (id: UniqueId) => {
        let response = await this.api.ReadMentorById(id)
        return response
    }

    public ReadMentorAmoFields = async () => {
        let response = await this.api.ReadMentorAmoFields()
        return response
    }

    public CreateMentor = async (name: string, amoUserId: number, stageAmoId: number, studyAmoId: number, isAmoId: number, callback?: ()=>void) => {
        this.stateActions.MentorCreateRequest()
        let response = await this.api.CreateMentor(name, amoUserId, stageAmoId, studyAmoId, isAmoId)
        if (response instanceof Error) {
            this.stateActions.MentorCreateError(response.message)
            return
        } else {
            this.stateActions.MentorCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }



    public UpdateMentor = async (id: string, name: string, amoUserId: number, stageAmoId: number, studyAmoId: number, isAmoId: number, callback?: ()=>void) => {
        this.stateActions.MentorUpdateRequest()
        let response = await this.api.UpdateMentor(id, name, amoUserId, stageAmoId, studyAmoId, isAmoId)
        if (response instanceof Error) {
            this.stateActions.MentorUpdateError(response.message)
            return
        } else {
            this.stateActions.MentorUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}