import { ReviewsRepository } from "../../repository/api/review/review";
import { actionCreators } from "../../state";

export class ReviewUseCases {
    private api: ReviewsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: ReviewsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public RequestReview = async (leadId: number, userId: number) => {
        let response = await this.api.RequestReview(leadId, userId)
        return response
    }

    public CreateReview = async (mentorId: string, anketId: string, anketVersionId: string, leadId: number, answers: object) => {
        let response = await this.api.CreateReview(mentorId, anketId, anketVersionId, leadId, answers)
        return response
    }

    public ReadReport = async (from: Date, to: Date, filter: {fio: string, isMentor: number, probationMentor: number, studyMentor: number, headOfGroup: number, isFired: null | boolean,}) => {
        let response = await this.api.ReadReport(from, to, filter)
        return response
    }

    public ReadReportXlsx = async (from: Date, to: Date) => {
        let response = await this.api.ReadReportXlsx(from, to)
        return response
    }


}