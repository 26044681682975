/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnketAnketResponse
 */
export interface AnketAnketResponse {
    /**
     * 
     * @type {string}
     * @memberof AnketAnketResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnketAnketResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnketAnketResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnketAnketResponse
     */
    'questions'?: Array<string>;
    /**
     * 
     * @type {DomainStage}
     * @memberof AnketAnketResponse
     */
    'stage'?: DomainStage;
}
/**
 * 
 * @export
 * @interface AnketCreateAnketRequest
 */
export interface AnketCreateAnketRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnketCreateAnketRequest
     */
    'questions'?: Array<string>;
    /**
     * 
     * @type {DomainStage}
     * @memberof AnketCreateAnketRequest
     */
    'stage'?: DomainStage;
}
/**
 * 
 * @export
 * @interface AnketUpdateAnketRequest
 */
export interface AnketUpdateAnketRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnketUpdateAnketRequest
     */
    'questions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface AuthSignInResponse
 */
export interface AuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface BackendInternalPortsRestReviewRequestReviewResponse
 */
export interface BackendInternalPortsRestReviewRequestReviewResponse {
    /**
     * 
     * @type {string}
     * @memberof BackendInternalPortsRestReviewRequestReviewResponse
     */
    'anket_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendInternalPortsRestReviewRequestReviewResponse
     */
    'anket_version_id'?: string;
    /**
     * 
     * @type {MentorMentorResponse}
     * @memberof BackendInternalPortsRestReviewRequestReviewResponse
     */
    'mentor'?: MentorMentorResponse;
    /**
     * 
     * @type {Array<QuestionQuestionResponse>}
     * @memberof BackendInternalPortsRestReviewRequestReviewResponse
     */
    'questions'?: Array<QuestionQuestionResponse>;
}
/**
 * 
 * @export
 * @interface DomainReviewReportFilter
 */
export interface DomainReviewReportFilter {
    /**
     * 
     * @type {string}
     * @memberof DomainReviewReportFilter
     */
    'fio'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomainReviewReportFilter
     */
    'head_of_group'?: number;
    /**
     * boolean or null
     * @type {object}
     * @memberof DomainReviewReportFilter
     */
    'is_fired'?: object;
    /**
     * 
     * @type {number}
     * @memberof DomainReviewReportFilter
     */
    'is_mentor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainReviewReportFilter
     */
    'probation_mentor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainReviewReportFilter
     */
    'study_mentor'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DomainStage = {
    Study: 'study',
    Internship: 'internship',
    Probation: 'probation'
} as const;

export type DomainStage = typeof DomainStage[keyof typeof DomainStage];


/**
 * 
 * @export
 * @interface DomainStageMetriks
 */
export interface DomainStageMetriks {
    /**
     * 
     * @type {number}
     * @memberof DomainStageMetriks
     */
    'profit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainStageMetriks
     */
    'romi'?: number;
    /**
     * 
     * @type {DomainStage}
     * @memberof DomainStageMetriks
     */
    'stage'?: DomainStage;
}
/**
 * 
 * @export
 * @interface InternAssignInternToRgRequest
 */
export interface InternAssignInternToRgRequest {
    /**
     * 
     * @type {string}
     * @memberof InternAssignInternToRgRequest
     */
    'intern_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof InternAssignInternToRgRequest
     */
    'rg_amo_id'?: number;
}
/**
 * 
 * @export
 * @interface InternAssignmentRgWithId
 */
export interface InternAssignmentRgWithId {
    /**
     * 
     * @type {number}
     * @memberof InternAssignmentRgWithId
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InternAssignmentRgWithId
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface InternInternResponse
 */
export interface InternInternResponse {
    /**
     * 
     * @type {number}
     * @memberof InternInternResponse
     */
    'age'?: number;
    /**
     * 
     * @type {number}
     * @memberof InternInternResponse
     */
    'amo_lead_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InternInternResponse
     */
    'assigned_to_rg'?: number;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'internship_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'probation_date'?: string;
    /**
     * 
     * @type {Array<DomainStageMetriks>}
     * @memberof InternInternResponse
     */
    'stage_metriks'?: Array<DomainStageMetriks>;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'state_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternInternResponse
     */
    'study_date'?: string;
}
/**
 * 
 * @export
 * @interface InternInternWithReviewesResponse
 */
export interface InternInternWithReviewesResponse {
    /**
     * 
     * @type {InternInternResponse}
     * @memberof InternInternWithReviewesResponse
     */
    'intern'?: InternInternResponse;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InternInternWithReviewesResponse
     */
    'reviewes'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface MentorCreateMentorRequest
 */
export interface MentorCreateMentorRequest {
    /**
     * 
     * @type {number}
     * @memberof MentorCreateMentorRequest
     */
    'amo_user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorCreateMentorRequest
     */
    'is_amo_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorCreateMentorRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MentorCreateMentorRequest
     */
    'stage_amo_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorCreateMentorRequest
     */
    'study_amo_id'?: number;
}
/**
 * 
 * @export
 * @interface MentorMentorResponse
 */
export interface MentorMentorResponse {
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'amo_user_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'is_amo_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'stage_amo_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'study_amo_id'?: number;
}
/**
 * 
 * @export
 * @interface MentorMentorWithId
 */
export interface MentorMentorWithId {
    /**
     * 
     * @type {number}
     * @memberof MentorMentorWithId
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorWithId
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MentorUpdateMentorRequest
 */
export interface MentorUpdateMentorRequest {
    /**
     * 
     * @type {number}
     * @memberof MentorUpdateMentorRequest
     */
    'amo_user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorUpdateMentorRequest
     */
    'is_amo_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorUpdateMentorRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MentorUpdateMentorRequest
     */
    'stage_amo_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorUpdateMentorRequest
     */
    'study_amo_id'?: number;
}
/**
 * 
 * @export
 * @interface QuestionCreateQuestionRequest
 */
export interface QuestionCreateQuestionRequest {
    /**
     * 
     * @type {object}
     * @memberof QuestionCreateQuestionRequest
     */
    'question_params'?: object;
    /**
     * 
     * @type {string}
     * @memberof QuestionCreateQuestionRequest
     */
    'question_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCreateQuestionRequest
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface QuestionQuestionResponse
 */
export interface QuestionQuestionResponse {
    /**
     * 
     * @type {string}
     * @memberof QuestionQuestionResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionQuestionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionQuestionResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {object}
     * @memberof QuestionQuestionResponse
     */
    'question_params'?: object;
    /**
     * 
     * @type {string}
     * @memberof QuestionQuestionResponse
     */
    'question_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionQuestionResponse
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface QuestionUpdateQuestionRequest
 */
export interface QuestionUpdateQuestionRequest {
    /**
     * 
     * @type {object}
     * @memberof QuestionUpdateQuestionRequest
     */
    'question_params'?: object;
    /**
     * 
     * @type {string}
     * @memberof QuestionUpdateQuestionRequest
     */
    'question_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionUpdateQuestionRequest
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface RestErrorResponse
 */
export interface RestErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ReviewAnswer
 */
export interface ReviewAnswer {
    /**
     * 
     * @type {string}
     * @memberof ReviewAnswer
     */
    'question_answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewAnswer
     */
    'question_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewAnswer
     */
    'question_title'?: string;
}
/**
 * 
 * @export
 * @interface ReviewCreateReviewRequest
 */
export interface ReviewCreateReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewCreateReviewRequest
     */
    'anket_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewCreateReviewRequest
     */
    'anket_version_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ReviewCreateReviewRequest
     */
    'answers'?: object;
    /**
     * 
     * @type {number}
     * @memberof ReviewCreateReviewRequest
     */
    'lead_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewCreateReviewRequest
     */
    'mentor_id'?: string;
}
/**
 * 
 * @export
 * @interface ReviewFired
 */
export interface ReviewFired {
    /**
     * 
     * @type {string}
     * @memberof ReviewFired
     */
    'cause'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewFired
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewFired
     */
    'stage'?: string;
}
/**
 * 
 * @export
 * @interface ReviewQuestionTitleId
 */
export interface ReviewQuestionTitleId {
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionTitleId
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewQuestionTitleId
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ReviewReviewReportForAnketVersion
 */
export interface ReviewReviewReportForAnketVersion {
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportForAnketVersion
     */
    'anket_version_date'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<ReviewQuestionTitleId>; }}
     * @memberof ReviewReviewReportForAnketVersion
     */
    'answer_question_ids_order'?: { [key: string]: Array<ReviewQuestionTitleId>; };
    /**
     * 
     * @type {Array<ReviewReviewReportItem>}
     * @memberof ReviewReviewReportForAnketVersion
     */
    'intern'?: Array<ReviewReviewReportItem>;
}
/**
 * 
 * @export
 * @interface ReviewReviewReportItem
 */
export interface ReviewReviewReportItem {
    /**
     * 
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'age'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'city'?: string;
    /**
     * 
     * @type {ReviewReviewReportItemFired}
     * @memberof ReviewReviewReportItem
     */
    'fired'?: ReviewReviewReportItemFired;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'head_of_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'intern'?: string;
    /**
     * 
     * @type {Array<ReviewAnswer>}
     * @memberof ReviewReviewReportItem
     */
    'internship_answers'?: Array<ReviewAnswer>;
    /**
     * read from external api
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'internship_cp'?: number;
    /**
     * read from amo
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'internship_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'internship_mentor_name'?: string;
    /**
     * read from external api
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'internship_romi'?: number;
    /**
     * 
     * @type {Array<ReviewAnswer>}
     * @memberof ReviewReviewReportItem
     */
    'probation_answers'?: Array<ReviewAnswer>;
    /**
     * read from external api
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'probation_cp'?: number;
    /**
     * read from amo
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'probation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'probation_mentor_name'?: string;
    /**
     * read from external api
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'probation_romi'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'range_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'range_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewReviewReportItem
     */
    'romi_dynamic'?: number;
    /**
     * 
     * @type {Array<ReviewAnswer>}
     * @memberof ReviewReviewReportItem
     */
    'study_answers'?: Array<ReviewAnswer>;
    /**
     * read from amo
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'study_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItem
     */
    'study_mentor_name'?: string;
}
/**
 * read from amo
 * @export
 * @interface ReviewReviewReportItemFired
 */
export interface ReviewReviewReportItemFired {
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItemFired
     */
    'cause'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItemFired
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewReportItemFired
     */
    'stage'?: string;
}

/**
 * AnketApi - axios parameter creator
 * @export
 */
export const AnketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/anket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить анкету по ее id.
         * @summary удалить анкету по ее id.
         * @param {string} id Идентификатор анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnketIdDelete', 'id', id)
            const localVarPath = `/api/anket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnketIdGet', 'id', id)
            const localVarPath = `/api/anket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание анкеты.
         * @summary создание анкеты.
         * @param {AnketCreateAnketRequest} data Данные для создания анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketPost: async (data: AnketCreateAnketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiAnketPost', 'data', data)
            const localVarPath = `/api/anket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание анкеты.
         * @summary создание анкеты.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketPublishPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/anket/publish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновить список вопросов из анкеты.
         * @summary обновить список вопросов из анкеты.
         * @param {string} id Идентификатор анкеты
         * @param {AnketUpdateAnketRequest} data Данные для создания анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketQuestionsIdPut: async (id: string, data: AnketUpdateAnketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnketQuestionsIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiAnketQuestionsIdPut', 'data', data)
            const localVarPath = `/api/anket/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnketApi - functional programming interface
 * @export
 */
export const AnketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnketApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnketGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnketAnketResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnketGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить анкету по ее id.
         * @summary удалить анкету по ее id.
         * @param {string} id Идентификатор анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnketIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnketIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnketIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnketAnketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnketIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание анкеты.
         * @summary создание анкеты.
         * @param {AnketCreateAnketRequest} data Данные для создания анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnketPost(data: AnketCreateAnketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnketAnketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnketPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание анкеты.
         * @summary создание анкеты.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnketPublishPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnketAnketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnketPublishPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновить список вопросов из анкеты.
         * @summary обновить список вопросов из анкеты.
         * @param {string} id Идентификатор анкеты
         * @param {AnketUpdateAnketRequest} data Данные для создания анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnketQuestionsIdPut(id: string, data: AnketUpdateAnketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnketAnketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnketQuestionsIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnketApi - factory interface
 * @export
 */
export const AnketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnketApiFp(configuration)
    return {
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketGet(options?: any): AxiosPromise<Array<AnketAnketResponse>> {
            return localVarFp.apiAnketGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить анкету по ее id.
         * @summary удалить анкету по ее id.
         * @param {string} id Идентификатор анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnketIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketIdGet(id: string, options?: any): AxiosPromise<AnketAnketResponse> {
            return localVarFp.apiAnketIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * создание анкеты.
         * @summary создание анкеты.
         * @param {AnketCreateAnketRequest} data Данные для создания анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketPost(data: AnketCreateAnketRequest, options?: any): AxiosPromise<AnketAnketResponse> {
            return localVarFp.apiAnketPost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * создание анкеты.
         * @summary создание анкеты.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketPublishPost(options?: any): AxiosPromise<AnketAnketResponse> {
            return localVarFp.apiAnketPublishPost(options).then((request) => request(axios, basePath));
        },
        /**
         * обновить список вопросов из анкеты.
         * @summary обновить список вопросов из анкеты.
         * @param {string} id Идентификатор анкеты
         * @param {AnketUpdateAnketRequest} data Данные для создания анкеты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnketQuestionsIdPut(id: string, data: AnketUpdateAnketRequest, options?: any): AxiosPromise<AnketAnketResponse> {
            return localVarFp.apiAnketQuestionsIdPut(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnketApi - object-oriented interface
 * @export
 * @class AnketApi
 * @extends {BaseAPI}
 */
export class AnketApi extends BaseAPI {
    /**
     * получить список анкет.
     * @summary получить список анкет.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnketApi
     */
    public apiAnketGet(options?: AxiosRequestConfig) {
        return AnketApiFp(this.configuration).apiAnketGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить анкету по ее id.
     * @summary удалить анкету по ее id.
     * @param {string} id Идентификатор анкеты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnketApi
     */
    public apiAnketIdDelete(id: string, options?: AxiosRequestConfig) {
        return AnketApiFp(this.configuration).apiAnketIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать анкету по ее id.
     * @summary прочитать анкету по ее id.
     * @param {string} id Идентификатор анкеты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnketApi
     */
    public apiAnketIdGet(id: string, options?: AxiosRequestConfig) {
        return AnketApiFp(this.configuration).apiAnketIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание анкеты.
     * @summary создание анкеты.
     * @param {AnketCreateAnketRequest} data Данные для создания анкеты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnketApi
     */
    public apiAnketPost(data: AnketCreateAnketRequest, options?: AxiosRequestConfig) {
        return AnketApiFp(this.configuration).apiAnketPost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание анкеты.
     * @summary создание анкеты.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnketApi
     */
    public apiAnketPublishPost(options?: AxiosRequestConfig) {
        return AnketApiFp(this.configuration).apiAnketPublishPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновить список вопросов из анкеты.
     * @summary обновить список вопросов из анкеты.
     * @param {string} id Идентификатор анкеты
     * @param {AnketUpdateAnketRequest} data Данные для создания анкеты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnketApi
     */
    public apiAnketQuestionsIdPut(id: string, data: AnketUpdateAnketRequest, options?: AxiosRequestConfig) {
        return AnketApiFp(this.configuration).apiAnketQuestionsIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<AuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternHookPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/intern/hook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInternHookPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternHookPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternHookPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiInternHookPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiInternHookPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiInternHookPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternApi - axios parameter creator
 * @export
 */
export const InternApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * распределить интерна Руководителю Группы (страшему оператору).
         * @summary распределить интерна Руководителю Группы (страшему оператору).
         * @param {InternAssignInternToRgRequest} data Данные для распределения интерна
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternAssignPost: async (data: InternAssignInternToRgRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiInternAssignPost', 'data', data)
            const localVarPath = `/api/intern/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * запросить обновление по стажеру.
         * @summary запросить обновление по стажеру.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternHookIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInternHookIdPost', 'id', id)
            const localVarPath = `/api/intern/hook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {number} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInternIdDelete', 'id', id)
            const localVarPath = `/api/intern/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить интерна и его анкеты.
         * @summary получить интерна и его анкеты.
         * @param {string} id Идентификатор интерна
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInternIdGet', 'id', id)
            const localVarPath = `/api/intern/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список интернов которых надо распределить руководителям групп.
         * @summary получить список интернов которых надо распределить руководителям групп.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternNotAssignedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/intern/not-assigned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список руководителей групп.
         * @summary получить список руководителей групп.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternRgListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/intern/rg-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternApi - functional programming interface
 * @export
 */
export const InternApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternApiAxiosParamCreator(configuration)
    return {
        /**
         * распределить интерна Руководителю Группы (страшему оператору).
         * @summary распределить интерна Руководителю Группы (страшему оператору).
         * @param {InternAssignInternToRgRequest} data Данные для распределения интерна
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInternAssignPost(data: InternAssignInternToRgRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternInternResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternAssignPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * запросить обновление по стажеру.
         * @summary запросить обновление по стажеру.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInternHookIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternHookIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {number} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInternIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить интерна и его анкеты.
         * @summary получить интерна и его анкеты.
         * @param {string} id Идентификатор интерна
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInternIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternInternWithReviewesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список интернов которых надо распределить руководителям групп.
         * @summary получить список интернов которых надо распределить руководителям групп.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInternNotAssignedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InternInternResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternNotAssignedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список руководителей групп.
         * @summary получить список руководителей групп.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInternRgListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InternAssignmentRgWithId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternRgListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternApi - factory interface
 * @export
 */
export const InternApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternApiFp(configuration)
    return {
        /**
         * распределить интерна Руководителю Группы (страшему оператору).
         * @summary распределить интерна Руководителю Группы (страшему оператору).
         * @param {InternAssignInternToRgRequest} data Данные для распределения интерна
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternAssignPost(data: InternAssignInternToRgRequest, options?: any): AxiosPromise<InternInternResponse> {
            return localVarFp.apiInternAssignPost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * запросить обновление по стажеру.
         * @summary запросить обновление по стажеру.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternHookIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiInternHookIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {number} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInternIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить интерна и его анкеты.
         * @summary получить интерна и его анкеты.
         * @param {string} id Идентификатор интерна
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternIdGet(id: string, options?: any): AxiosPromise<InternInternWithReviewesResponse> {
            return localVarFp.apiInternIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список интернов которых надо распределить руководителям групп.
         * @summary получить список интернов которых надо распределить руководителям групп.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternNotAssignedGet(options?: any): AxiosPromise<Array<InternInternResponse>> {
            return localVarFp.apiInternNotAssignedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить список руководителей групп.
         * @summary получить список руководителей групп.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInternRgListGet(options?: any): AxiosPromise<Array<InternAssignmentRgWithId>> {
            return localVarFp.apiInternRgListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternApi - object-oriented interface
 * @export
 * @class InternApi
 * @extends {BaseAPI}
 */
export class InternApi extends BaseAPI {
    /**
     * распределить интерна Руководителю Группы (страшему оператору).
     * @summary распределить интерна Руководителю Группы (страшему оператору).
     * @param {InternAssignInternToRgRequest} data Данные для распределения интерна
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternApi
     */
    public apiInternAssignPost(data: InternAssignInternToRgRequest, options?: AxiosRequestConfig) {
        return InternApiFp(this.configuration).apiInternAssignPost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * запросить обновление по стажеру.
     * @summary запросить обновление по стажеру.
     * @param {string} id Идентификатор ментора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternApi
     */
    public apiInternHookIdPost(id: string, options?: AxiosRequestConfig) {
        return InternApiFp(this.configuration).apiInternHookIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * уволить интерна по amo id.
     * @summary уволить интерна по amo id.
     * @param {number} id Идентификатор ментора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternApi
     */
    public apiInternIdDelete(id: number, options?: AxiosRequestConfig) {
        return InternApiFp(this.configuration).apiInternIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить интерна и его анкеты.
     * @summary получить интерна и его анкеты.
     * @param {string} id Идентификатор интерна
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternApi
     */
    public apiInternIdGet(id: string, options?: AxiosRequestConfig) {
        return InternApiFp(this.configuration).apiInternIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список интернов которых надо распределить руководителям групп.
     * @summary получить список интернов которых надо распределить руководителям групп.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternApi
     */
    public apiInternNotAssignedGet(options?: AxiosRequestConfig) {
        return InternApiFp(this.configuration).apiInternNotAssignedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список руководителей групп.
     * @summary получить список руководителей групп.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternApi
     */
    public apiInternRgListGet(options?: AxiosRequestConfig) {
        return InternApiFp(this.configuration).apiInternRgListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MentorApi - axios parameter creator
 * @export
 */
export const MentorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список id менторов из полей в амо.
         * @summary получить список id менторов из полей в амо.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorAmoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mentor/amo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mentor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить ментора по его id.
         * @summary удалить ментора по его id.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMentorIdDelete', 'id', id)
            const localVarPath = `/api/mentor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMentorIdGet', 'id', id)
            const localVarPath = `/api/mentor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновить ментора.
         * @summary обновить ментора.
         * @param {string} id Идентификатор ментора
         * @param {MentorUpdateMentorRequest} data Данные для создания ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorIdPut: async (id: string, data: MentorUpdateMentorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMentorIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiMentorIdPut', 'data', data)
            const localVarPath = `/api/mentor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание ментора.
         * @summary создание ментора.
         * @param {MentorCreateMentorRequest} data Данные для создания ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorPost: async (data: MentorCreateMentorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiMentorPost', 'data', data)
            const localVarPath = `/api/mentor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MentorApi - functional programming interface
 * @export
 */
export const MentorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MentorApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список id менторов из полей в амо.
         * @summary получить список id менторов из полей в амо.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorAmoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<MentorMentorWithId>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorAmoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MentorMentorResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить ментора по его id.
         * @summary удалить ментора по его id.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MentorMentorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновить ментора.
         * @summary обновить ментора.
         * @param {string} id Идентификатор ментора
         * @param {MentorUpdateMentorRequest} data Данные для создания ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorIdPut(id: string, data: MentorUpdateMentorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MentorMentorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание ментора.
         * @summary создание ментора.
         * @param {MentorCreateMentorRequest} data Данные для создания ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorPost(data: MentorCreateMentorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MentorMentorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MentorApi - factory interface
 * @export
 */
export const MentorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MentorApiFp(configuration)
    return {
        /**
         * получить список id менторов из полей в амо.
         * @summary получить список id менторов из полей в амо.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorAmoGet(options?: any): AxiosPromise<{ [key: string]: Array<MentorMentorWithId>; }> {
            return localVarFp.apiMentorAmoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorGet(options?: any): AxiosPromise<Array<MentorMentorResponse>> {
            return localVarFp.apiMentorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить ментора по его id.
         * @summary удалить ментора по его id.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMentorIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorIdGet(id: string, options?: any): AxiosPromise<MentorMentorResponse> {
            return localVarFp.apiMentorIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновить ментора.
         * @summary обновить ментора.
         * @param {string} id Идентификатор ментора
         * @param {MentorUpdateMentorRequest} data Данные для создания ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorIdPut(id: string, data: MentorUpdateMentorRequest, options?: any): AxiosPromise<MentorMentorResponse> {
            return localVarFp.apiMentorIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * создание ментора.
         * @summary создание ментора.
         * @param {MentorCreateMentorRequest} data Данные для создания ментора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorPost(data: MentorCreateMentorRequest, options?: any): AxiosPromise<MentorMentorResponse> {
            return localVarFp.apiMentorPost(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MentorApi - object-oriented interface
 * @export
 * @class MentorApi
 * @extends {BaseAPI}
 */
export class MentorApi extends BaseAPI {
    /**
     * получить список id менторов из полей в амо.
     * @summary получить список id менторов из полей в амо.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorApi
     */
    public apiMentorAmoGet(options?: AxiosRequestConfig) {
        return MentorApiFp(this.configuration).apiMentorAmoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список анкет.
     * @summary получить список анкет.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorApi
     */
    public apiMentorGet(options?: AxiosRequestConfig) {
        return MentorApiFp(this.configuration).apiMentorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить ментора по его id.
     * @summary удалить ментора по его id.
     * @param {string} id Идентификатор ментора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorApi
     */
    public apiMentorIdDelete(id: string, options?: AxiosRequestConfig) {
        return MentorApiFp(this.configuration).apiMentorIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать анкету по ее id.
     * @summary прочитать анкету по ее id.
     * @param {string} id Идентификатор ментора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorApi
     */
    public apiMentorIdGet(id: string, options?: AxiosRequestConfig) {
        return MentorApiFp(this.configuration).apiMentorIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновить ментора.
     * @summary обновить ментора.
     * @param {string} id Идентификатор ментора
     * @param {MentorUpdateMentorRequest} data Данные для создания ментора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorApi
     */
    public apiMentorIdPut(id: string, data: MentorUpdateMentorRequest, options?: AxiosRequestConfig) {
        return MentorApiFp(this.configuration).apiMentorIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание ментора.
     * @summary создание ментора.
     * @param {MentorCreateMentorRequest} data Данные для создания ментора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorApi
     */
    public apiMentorPost(data: MentorCreateMentorRequest, options?: AxiosRequestConfig) {
        return MentorApiFp(this.configuration).apiMentorPost(data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить вопроса по его id.
         * @summary удалить вопроса по его id.
         * @param {string} id Идентификатор вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiQuestionIdDelete', 'id', id)
            const localVarPath = `/api/question/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiQuestionIdGet', 'id', id)
            const localVarPath = `/api/question/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновить вопроса.
         * @summary обновить вопроса.
         * @param {string} id Идентификатор вопроса
         * @param {QuestionUpdateQuestionRequest} data Данные для создания вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionIdPut: async (id: string, data: QuestionUpdateQuestionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiQuestionIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiQuestionIdPut', 'data', data)
            const localVarPath = `/api/question/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание вопроса.
         * @summary создание вопроса.
         * @param {QuestionCreateQuestionRequest} data Данные для создания вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionPost: async (data: QuestionCreateQuestionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiQuestionPost', 'data', data)
            const localVarPath = `/api/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionQuestionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить вопроса по его id.
         * @summary удалить вопроса по его id.
         * @param {string} id Идентификатор вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновить вопроса.
         * @summary обновить вопроса.
         * @param {string} id Идентификатор вопроса
         * @param {QuestionUpdateQuestionRequest} data Данные для создания вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionIdPut(id: string, data: QuestionUpdateQuestionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание вопроса.
         * @summary создание вопроса.
         * @param {QuestionCreateQuestionRequest} data Данные для создания вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionPost(data: QuestionCreateQuestionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * получить список анкет.
         * @summary получить список анкет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGet(options?: any): AxiosPromise<Array<QuestionQuestionResponse>> {
            return localVarFp.apiQuestionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить вопроса по его id.
         * @summary удалить вопроса по его id.
         * @param {string} id Идентификатор вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать анкету по ее id.
         * @summary прочитать анкету по ее id.
         * @param {string} id Идентификатор вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionIdGet(id: string, options?: any): AxiosPromise<QuestionQuestionResponse> {
            return localVarFp.apiQuestionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновить вопроса.
         * @summary обновить вопроса.
         * @param {string} id Идентификатор вопроса
         * @param {QuestionUpdateQuestionRequest} data Данные для создания вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionIdPut(id: string, data: QuestionUpdateQuestionRequest, options?: any): AxiosPromise<QuestionQuestionResponse> {
            return localVarFp.apiQuestionIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * создание вопроса.
         * @summary создание вопроса.
         * @param {QuestionCreateQuestionRequest} data Данные для создания вопроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionPost(data: QuestionCreateQuestionRequest, options?: any): AxiosPromise<QuestionQuestionResponse> {
            return localVarFp.apiQuestionPost(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
    /**
     * получить список анкет.
     * @summary получить список анкет.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionGet(options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить вопроса по его id.
     * @summary удалить вопроса по его id.
     * @param {string} id Идентификатор вопроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionIdDelete(id: string, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать анкету по ее id.
     * @summary прочитать анкету по ее id.
     * @param {string} id Идентификатор вопроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionIdGet(id: string, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновить вопроса.
     * @summary обновить вопроса.
     * @param {string} id Идентификатор вопроса
     * @param {QuestionUpdateQuestionRequest} data Данные для создания вопроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionIdPut(id: string, data: QuestionUpdateQuestionRequest, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание вопроса.
     * @summary создание вопроса.
     * @param {QuestionCreateQuestionRequest} data Данные для создания вопроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionPost(data: QuestionCreateQuestionRequest, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionPost(data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReviewApi - axios parameter creator
 * @export
 */
export const ReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * запросить анкету по id лида и пользователя в амо.
         * @summary запросить анкету по id лида и пользователя в амо.
         * @param {string} leadId Идентификатор лида в амо
         * @param {string} userId Идентификатор пользователя в амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewLeadIdUserIdGet: async (leadId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadId' is not null or undefined
            assertParamExists('apiReviewLeadIdUserIdGet', 'leadId', leadId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiReviewLeadIdUserIdGet', 'userId', userId)
            const localVarPath = `/api/review/{leadId}/{userId}`
                .replace(`{${"leadId"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * запросить анкету по id лида и пользователя в амо.
         * @summary запросить анкету по id лида и пользователя в амо.
         * @param {ReviewCreateReviewRequest} data Данные для сохранения в анкете
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPost: async (data: ReviewCreateReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiReviewPost', 'data', data)
            const localVarPath = `/api/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {DomainReviewReportFilter} data Данные для фильтрации
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewReportPost: async (data: DomainReviewReportFilter, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiReviewReportPost', 'data', data)
            const localVarPath = `/api/review/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewReportXlsxPost: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/review/report/xlsx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewApi - functional programming interface
 * @export
 */
export const ReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * запросить анкету по id лида и пользователя в амо.
         * @summary запросить анкету по id лида и пользователя в амо.
         * @param {string} leadId Идентификатор лида в амо
         * @param {string} userId Идентификатор пользователя в амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewLeadIdUserIdGet(leadId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackendInternalPortsRestReviewRequestReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewLeadIdUserIdGet(leadId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * запросить анкету по id лида и пользователя в амо.
         * @summary запросить анкету по id лида и пользователя в амо.
         * @param {ReviewCreateReviewRequest} data Данные для сохранения в анкете
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewPost(data: ReviewCreateReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {DomainReviewReportFilter} data Данные для фильтрации
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewReportPost(data: DomainReviewReportFilter, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewReviewReportForAnketVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReportPost(data, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewReportXlsxPost(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReportXlsxPost(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewApi - factory interface
 * @export
 */
export const ReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewApiFp(configuration)
    return {
        /**
         * запросить анкету по id лида и пользователя в амо.
         * @summary запросить анкету по id лида и пользователя в амо.
         * @param {string} leadId Идентификатор лида в амо
         * @param {string} userId Идентификатор пользователя в амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewLeadIdUserIdGet(leadId: string, userId: string, options?: any): AxiosPromise<BackendInternalPortsRestReviewRequestReviewResponse> {
            return localVarFp.apiReviewLeadIdUserIdGet(leadId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * запросить анкету по id лида и пользователя в амо.
         * @summary запросить анкету по id лида и пользователя в амо.
         * @param {ReviewCreateReviewRequest} data Данные для сохранения в анкете
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPost(data: ReviewCreateReviewRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiReviewPost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {DomainReviewReportFilter} data Данные для фильтрации
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewReportPost(data: DomainReviewReportFilter, from?: string, to?: string, options?: any): AxiosPromise<Array<ReviewReviewReportForAnketVersion>> {
            return localVarFp.apiReviewReportPost(data, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewReportXlsxPost(from?: string, to?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiReviewReportXlsxPost(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewApi - object-oriented interface
 * @export
 * @class ReviewApi
 * @extends {BaseAPI}
 */
export class ReviewApi extends BaseAPI {
    /**
     * запросить анкету по id лида и пользователя в амо.
     * @summary запросить анкету по id лида и пользователя в амо.
     * @param {string} leadId Идентификатор лида в амо
     * @param {string} userId Идентификатор пользователя в амо
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewLeadIdUserIdGet(leadId: string, userId: string, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewLeadIdUserIdGet(leadId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * запросить анкету по id лида и пользователя в амо.
     * @summary запросить анкету по id лида и пользователя в амо.
     * @param {ReviewCreateReviewRequest} data Данные для сохранения в анкете
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewPost(data: ReviewCreateReviewRequest, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewPost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * уволить интерна по amo id.
     * @summary уволить интерна по amo id.
     * @param {DomainReviewReportFilter} data Данные для фильтрации
     * @param {string} [from] дата передачи лида от
     * @param {string} [to] дата передачи лида до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewReportPost(data: DomainReviewReportFilter, from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewReportPost(data, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * уволить интерна по amo id.
     * @summary уволить интерна по amo id.
     * @param {string} [from] дата передачи лида от
     * @param {string} [to] дата передачи лида до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewReportXlsxPost(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewReportXlsxPost(from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


