export class Question {
     
    private id: string; 
    private title: string; 
    private questionType: string;
    private questionParams: Object; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, title: string, questionType: string, questionParams: Object, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.title = title;
        this.questionType = questionType;
        this.createdAt = createdAt;
        this.questionParams = questionParams;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }

    public Title = (): string => {
        return this.title
    }

    public QuestionType = (): string => {
        return this.questionType
    }

    public QuestionParams = (): Object => {
        return this.questionParams
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}