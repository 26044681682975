import { useParams } from "react-router-dom";
import { UseCasesContext } from "../../../../context/useCases"
import { useContext, useEffect, useState } from "react";
import { Mentor } from "../../../../domain/mentor/mentor";
import { Anket } from "../../../../domain/anket/anket";
import { RangeQuestion } from "./range-question";
import { TestQuestion } from "./test-question";
import { CommentQuestion } from "./comment-question";
import { Question } from "../../../../domain/question/question";
import { Check, X } from "tabler-icons-react";

export const AmoReview = () => {
    let { leadId, userId } = useParams();

    const [loading, setLoading] = useState(true)
    const [reviewParams, setReviewParams] = useState<{ mentor: Mentor, anketId: UniqueId, anketVersionId: UniqueId, questions: Question[] } | null>()
    const [error, setError] = useState<string | null>()
    const [submitError, setSubmitError] = useState<string | null>()
    const [success, setSuccess] = useState(false)
    const [answers, setAnswers] = useState<{ [key: string]: any }>({})
    const [isFired, setIsFired] = useState(false)
    const [submitSend, setSubmitSend ] = useState(false)
    let useCases = useContext(UseCasesContext)

    const readReviewParamsById = async (leadId: number, userId: number) => {
        setReviewParams(null)
        let response = await useCases?.reviewUseCase.RequestReview(leadId, userId)
        setLoading(false)
        if (response instanceof Error) {
            if (response.message === "review already exists") {
                //ответ уже был записан
                setSuccess(true)
                return
            } else if (response.message === "intern is fired") {
                setIsFired(true)
                return
            }
            setError(response.message!)
        } else {
            setReviewParams(response!)
            setError(null)
        }

    }

    const setAnswerInList = (id: string, val: any) => {
        let newAnswers: { [key: string]: any } = {}
        let isExists = false
        Object.keys(answers).forEach((key) => {
            if (key === id) {
                newAnswers[key] = val
                isExists = true
            } else {
                newAnswers[key] = answers[key]
            }
        });
        if (!isExists) {
            newAnswers[id] = val
        }
        setAnswers(newAnswers)
    }

    const submitReview = async () => {
        if (submitSend) {
            return
        }
        setSubmitSend(true)
        let response = await useCases?.reviewUseCase.CreateReview(reviewParams?.mentor.Id()!, reviewParams?.anketId!, reviewParams?.anketVersionId!, parseInt(leadId!), answers)
        setSubmitSend(false)
        if (response instanceof Error) {
            setSubmitError("Ошибка отправки: " + response.message)
        } else {
            setSuccess(true)
            setSubmitError(null)
        }
    }

    useEffect(() => {
        if (leadId && userId) {
            readReviewParamsById(parseInt(leadId), parseInt(userId))
        }
    }, [leadId, userId])

    if (loading) {
        return <>loading</>
    }

    return (
        <div className="layout-page" >
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4">Карточка стажера</h4>
                        </div>
                        {
                            isFired ? <div className="card pt-5 pb-5">
                            <div className="text-center mb-4">
                                <h3 className="mb-2">Стажер уволен</h3>
                            </div>
                            <div className="col-12 col-lg-12 px-4">
                                <div className="d-flex justify-content-center mb-4">
                                    <div className="modal-refer-and-earn-step bg-label-danger" style={{ borderRadius: "10px" }}>
                                        <X size={90} />
                                    </div>
                                </div>

                            </div>
                        </div>: error ?
                            <div className="card">
                                <div className="alert alert-danger" role="alert">{error}</div>
                            </div> :

                            !success ?
                                <div className="card">
                                   
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <span style={{ fontSize: 15, fontWeight: "bold" }}>Пожалуйста ответьте на вопросы о кандидиате:</span>
                                    </div>
                                    <div className="card-body">
                                        {reviewParams?.questions.map((question) => {
                                            if (question.QuestionType() === "range") {
                                                let params = question.QuestionParams() as { from: number, to: number }
                                                return <RangeQuestion title={question.Title()} min={params.from} max={params.to} answer={answers[question.Id()]} setAnswer={(val: number) => { setAnswerInList(question.Id(), val) }} />
                                            }

                                            if (question.QuestionType() === "variants") {
                                                let variants = question.QuestionParams() as string[]
                                                return <TestQuestion title={question.Title()} variants={variants} answer={answers[question.Id()]} setAnswer={(val: number) => { setAnswerInList(question.Id(), val) }} />
                                            }

                                            if (question.QuestionType() === "comment") {
                                                let variants = question.QuestionParams() as string[]
                                                return <CommentQuestion title={question.Title()} answer={answers[question.Id()]} setAnswer={(val: string) => { setAnswerInList(question.Id(), val) }} />
                                            }
                                        })}

                                    </div>
                                    <div>
                                        {submitError ?
                                            <div className="alert alert-danger" role="alert">{submitError}</div>
                                            : <></>}
                                        <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                            <div className="dt-buttons py-3 mb-4">
                                                <button className="dt-button add-new btn btn-primary mb-3 mb-md-0" onClick={(e) => { e.preventDefault(); submitReview(); }} ><span>{submitSend?"Обработка...":"Отправить"}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <div className="card pt-5 pb-5">
                                    <div className="text-center mb-4">
                                        <h3 className="mb-2">Спасибо!</h3>
                                    </div>
                                    <div className="col-12 col-lg-12 px-4">
                                        <div className="d-flex justify-content-center mb-4">
                                            <div className="modal-refer-and-earn-step bg-label-primary" style={{ borderRadius: "10px" }}>
                                                <Check size={90} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="text-center mt-4">
                                        <p className="mb-lg-0">Ваш ответ успешно записан 👩🏻‍💻</p>
                                    </div>
                                </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}