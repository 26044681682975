import { Review } from "../../domain/review/review";
import { ReviewActionTypes } from "../action-types";
import { ReviewActions } from "../actions";
import produce from 'immer';


interface ReviewState {
    loading: boolean | null;
    reviews: Review[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: ReviewState = {
    loading: null,
    reviews: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: ReviewState = initialState, action: ReviewActions) => {
    switch (action.type) {
        case ReviewActionTypes.REVIEW_REQUEST_SEND:
            state.loading = true;
            return state;
        case ReviewActionTypes.REVIEW_SUCCESS:
            state.loading = false;
            state.reviews = action.payload;
            return state;
        case ReviewActionTypes.REVIEW_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case ReviewActionTypes.REVIEW_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case ReviewActionTypes.REVIEW_CREATE_SUCCESS:
            state.entityLoading = false
            state.reviews!.push(action.payload)
            state.entityError = ""
            return state;
        case ReviewActionTypes.REVIEW_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case ReviewActionTypes.REVIEW_DELETE_SUCCESS:
            state.reviews = state.reviews!.filter((reviews) => reviews.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
