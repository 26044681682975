import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { AnketList } from "../../pages/anket/list";
import { AnketAdd } from "../../pages/anket/add";
import { Mentor } from "../../../domain/mentor/mentor";
import { MentorList } from "../../pages/mentor/list";
import { MentorAdd } from "../../pages/mentor/add";
import { QuestionAdd } from "../../pages/questions/add";
import { AmoReview } from "../../pages/review/amo-review/amo_review";
import { ReviewReportXlsx } from "../../pages/review/report-xlsx";
import { InternsForAssign } from "../../pages/intern/interns-for-assign";
import { InternsAssignment } from "../../pages/intern/intern-assignment";
import { ReviewReport } from "../../pages/review/report";

export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(()=>{
      useCases?.authUseCase.CheckAuthorization()
    },10000)
  }, [])
 
  
  return (<Router basename="/">
    <Routes>
      {login?.authorized ? <>
        <Route path="/interns/:id" element={<InternsAssignment />} />
        <Route path="/interns" element={<InternsForAssign />} />
        <Route path="/review/report" element={<ReviewReport />} />
        {/*<Route path="/review/report/xlsx" element={<ReviewReportXlsx />} />*/}

        <Route path="/mentor/edit/:id" element={<MentorAdd />} />
        <Route path="/mentor/add" element={<MentorAdd />} />
        <Route path="/mentor" element={<MentorList />} />

        <Route path="/question/add" element={<QuestionAdd />} />
        <Route path="/anket/edit/:id" element={<AnketAdd />} />
        <Route path="/anket/add" element={<AnketAdd />} />
        <Route path="*" element={<AnketList />} />
      </> : <>
        <Route path="*" element={<Login />} />
        <Route path="/review/:leadId/:userId" element={<AmoReview />} />
      </>}
    </Routes>
  </Router>
  )
}