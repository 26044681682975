import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Menu } from "../../../components/menu"
import { Intern, StageMetriks } from "../../../../domain/intern/intern"
import { CheckCircle } from "react-feather"
import parse from 'html-react-parser';
import { InternAssignmentRgWithId } from "../../../../repository/api/generated"

export const InternsAssignment = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [intern, setIntern] = useState<Intern | null>()
    const [reviewes, setReviewes] = useState<{ [key: string]: string } | undefined>()
    const [rgList, setRgList] = useState<InternAssignmentRgWithId[]>([])
    const [form, setForm] = useState<{
        rgId: number | "",
    }>({
        rgId: "",
    })


    let useCases = useContext(UseCasesContext)

    const readInternWithReviewes = async () => {
        let response = await useCases?.internUseCase.ReadInternWithReviewes(id!)
        if (response instanceof Error) {

        } else {
            setIntern(response?.intern!)
            setReviewes(response?.reviewes)
        }
    }

    const readRgList = async () => {
        let response = await useCases?.internUseCase.ReadRgList()
        if (response instanceof Error) {

        } else {
            setRgList(response!)
        }
    }

    const submitRg = () => {
        if (form.rgId != "") {
            useCases?.internUseCase.AssignInternToRg(id!, form.rgId, ()=>navigate(-1))
        }
    }

    useEffect(() => {
        readInternWithReviewes()
        readRgList()
    }, [])

    const getMetriksMap = () => {
        const map: Map<string, StageMetriks> = new Map();
        intern?.StageMetriks().forEach((sm) => {
            map.set(sm.Stage(), sm)
        })

        return map
    }



    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-xl-5 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Установка Руководителя группы для стажера / </span> Стажеры</h4>
                        </div>

                        <div className="row">
                            {intern ? <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="user-avatar-section">
                                            <div className="d-flex align-items-center flex-column">
                                                <div className="user-info text-center">
                                                    <h4 className="mb-2">{intern.Name()}</h4>
                                                    <span className="badge bg-label-secondary mt-1">Стажер</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-around flex-wrap mt-3 pt-3 pb-4 border-bottom">
                                            <div className="d-flex align-items-start me-4 mt-3 gap-2">
                                                <span className="badge bg-label-primary p-2 rounded"><CheckCircle /></span>
                                                <div>
                                                    <p className="mb-0 ">romi = <b>{getMetriksMap().get("internship")?.Romi().toFixed(2)}</b></p>
                                                    <p className="mb-0 ">profit = <b>{getMetriksMap().get("internship")?.Profit().toFixed(2)}</b></p>
                                                    <small><b>Стажировка</b></small>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start mt-3 gap-2">
                                                <span className="badge bg-label-success p-2 rounded"><CheckCircle /></span>
                                                <div>
                                                    <p className="mb-0">romi = <b>{getMetriksMap().get("probation")?.Romi().toFixed(2)}</b></p>
                                                    <p className="mb-0">profit = <b>{getMetriksMap().get("probation")?.Profit().toFixed(2)}</b></p>
                                                    <small><b>Испытательный</b></small>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="mt-4 small text-uppercase text-muted">Подробности</p>
                                        <div className="info-container">
                                            <ul className="list-unstyled">
                                                <li className="mb-2 pt-1">
                                                    <span className="fw-semibold me-1">Возраст:</span>
                                                    <span>{intern.Age()}</span>
                                                </li>
                                                <li className="mb-2 pt-1">
                                                    <span className="fw-semibold me-1">Пол:</span>
                                                    <span className="badge bg-label-success">{intern.Gender()}</span>
                                                </li>
                                                <li className="mb-2 pt-1">
                                                    <span className="fw-semibold me-1">Город:</span>
                                                    <span>{intern.City()}</span>
                                                </li>
                                                <li className="mb-2 pt-1">
                                                    <span className="fw-semibold me-1">Ссылка на лид в амо</span>
                                                    <span><a href={"https://hrleadgen.amocrm.ru/leads/detail/" + intern.AmoLeadId()} target="_blank">{intern.AmoLeadId()} (Открыть)</a></span>
                                                </li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div> : <></>}

                            {reviewes ? <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1"><div className="card mb-4">
                                <h5 className="card-header">Опросники на этапах</h5>
                                <div className="card-body pb-0">
                                    <ul className="timeline mb-0">
                                        <li className="timeline-item timeline-item-transparent">
                                            <span className="timeline-point timeline-point-primary"></span>
                                            <div className="timeline-event">
                                                <div className="timeline-header mb-1">
                                                    <h6 className="mb-0">Обучение</h6>

                                                </div>
                                                {reviewes["study"] && reviewes["study"].split("\n").map((item) => {
                                                    return <p className="mb-1">{item}</p>
                                                })}
                                            </div>
                                        </li>
                                        <li className="timeline-item timeline-item-transparent">
                                            <span className="timeline-point timeline-point-warning"></span>
                                            <div className="timeline-event">
                                                <div className="timeline-header mb-1">
                                                    <h6 className="mb-0">Стажировка</h6>

                                                </div>
                                                {reviewes["internship"] && reviewes["internship"].split("\n").map((item) => {
                                                    return <p className="mb-1">{item}</p>
                                                })}
                                            </div>
                                        </li>

                                        <li className="timeline-item timeline-item-transparent border-0">
                                            <span className="timeline-point timeline-point-info"></span>
                                            <div className="timeline-event">
                                                <div className="timeline-header mb-1">
                                                    <h6 className="mb-0">Испытательный срок</h6>
                                                </div>
                                                {reviewes["probation"] && reviewes["probation"].split("\n").map((item) => {
                                                    return <p className="mb-1">{item}</p>
                                                })}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div></div> : <></>}
                            <div className="col-xl-12 col-lg-12 col-md-12 order-0 order-md-1">
                                <div className="card" style={{ background: "#fff", }}>
                                    <h5 className="card-header">Установка руководителя группы</h5>
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlSelect1" className="form-label">Выберите руководителя для стажера из списка ниже</label>
                                            <select value={form.rgId} onChange={(e) => {
                                                if (e.target.value != "") {
                                                    setForm({ ...form, rgId: parseInt(e.target.value) })
                                                }
                                            }} className="form-select" id="exampleFormControlSelect1" aria-label="Default select example">
                                                <option disabled value={""} >Выберите руководителя</option>
                                                {rgList.map((rg) => {
                                                    return <option value={rg.id!}>{rg.name!}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <b></b>
                                            <button className="btn btn-primary btn-next waves-effect waves-light" onClick={() => submitRg()}>
                                                <span className="align-middle d-sm-inline-block d-none me-sm-1">Сохранить</span>
                                                <i className="ti ti-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )

}
