import axios from "axios";
import { Intern, StageMetriks } from "../../../domain/intern/intern";
import { Configuration, InternApi, InternAssignmentRgWithId, InternInternResponse } from "../generated";
import { InternRepositoryInterface } from "../../../useCase/intern/adapters/repository/interface";

export class InternsRepository implements InternRepositoryInterface {
    private service: InternApi;

    constructor() {
        this.service = new InternApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async SetInternIsFired(id: number): Promise<string | Error> {
        try {

            let response = await this.service.apiInternIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "ok"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadInternsForAssign(): Promise<Intern[] | Error> {
        try {

            let response = await this.service.apiInternNotAssignedGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let interns: Intern[] = []
                response.data.map((internApi) =>{
                    interns.push(this.toDomainIntern(internApi))
                })

                return interns
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadInternWithReviewes(internId: UniqueId): Promise<{intern: Intern, reviewes: {[key: string]: string}} | Error> {
        try {

            let response = await this.service.apiInternIdGet(internId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let intern: Intern = this.toDomainIntern(response.data.intern!)
               

                return {intern: intern, reviewes: response.data.reviewes!}
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async AssignInternToRg(internId: UniqueId, rgAmoId: number): Promise<Intern | Error> {
        try {
            let response = await this.service.apiInternAssignPost({intern_id: internId, rg_amo_id: rgAmoId}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainIntern(response.data!)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadRgList(): Promise<InternAssignmentRgWithId[] | Error> {
        try {

            let response = await this.service.apiInternRgListGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
            
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }


    toDomainIntern = (intern: InternInternResponse): Intern => {
        let stageMetriks: StageMetriks[] = [];
        intern.stage_metriks!.forEach((sm) => {
            stageMetriks.push(new StageMetriks(sm.stage!, sm.profit!, sm.romi!))
        })
        return new Intern(intern.id!, intern.amo_lead_id!, intern.name!, intern.age!, intern.city!, intern.gender!, new Date(intern.study_date!), new Date(intern.internship_date!), new Date(intern.probation_date!), new Date(intern.state_date!), intern.assigned_to_rg!, stageMetriks, new Date(intern.created_at!), new Date(intern.modified_at!));
    }

}