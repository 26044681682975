import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Buffer } from 'buffer';
import { Trash } from "tabler-icons-react";

type props = {
    state: State,
    fields: Field[],
    politics?: boolean,
    submitBtnTitle: string | null,
    submit: any;
    updateForm: (name: string, value: any) => void;
    btnSmall?: boolean,
}

interface State {
    loading: boolean | undefined;
    error: string | null | undefined;
}

export interface Field {
    name: string
    title: string
    placeholder: string
    type: FiledType
    autofocus?: boolean
    param?: boolean
    options?: Option[],
    accept?: string,
    value?: any,
    bytes?: boolean,
    callbackAdd?: () => void,
}

interface Option {
    title: string,
    value: any,
}

export enum FiledType {
    Text = "text",
    MultiSelect = "multiselect",
    Select = "select",
    SelectInt = "selectInt",
    SelectList = "select_list",
    TextArea = "textarea",
    Password = "password",
    Email = "email",
    Number = "number",
    StringsList = "strings_list",
    File = "file",
    Color = "color",
    Date = "date",
}

export const Form = (props: props) => {
    const { register, handleSubmit } = useForm();

    const inputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        props.updateForm(e.target.name, e.target.value)
    }

    const inputChangeIndex = (index: number, fieldName: string, oldValue: string[], input: string) => {
        let newValue: string[] = []

        oldValue.forEach((val, ind) => {
            if (ind == index) {
                newValue.push(input)
            } else {
                newValue.push(val)
            }
        })

        props.updateForm(fieldName, newValue)
    }

    const deleteIndex = (index: number, fieldName: string, oldValue: string[],) => {
        let newValue: string[] = []

        oldValue.forEach((val, ind) => {
            if (ind == index) {

            } else {
                newValue.push(val)
            }
        })

        props.updateForm(fieldName, newValue)
    }

    const inputDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        props.updateForm(e.target.name, new Date(e.target.value))
    }

    const fileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files?.length > 0) {
            let file = e.target.files[0]
            if (!e.target.classList.contains('bytes')) {
                props.updateForm(
                    e.target.name, file,
                )
            } else {
                let file = e.target.files[0]
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (event.target?.result instanceof ArrayBuffer) {
                        props.updateForm(
                            e.target.name, toBuffer(event.target.result).toJSON().data,
                        )
                    }
                };
                reader.readAsArrayBuffer(file);
            }
        }
    }

    const toBuffer = (arrayBuffer: ArrayBuffer): Buffer => {
        const buffer = Buffer.alloc(arrayBuffer.byteLength);
        const view = new Uint8Array(arrayBuffer);
        for (let i = 0; i < buffer.length; ++i) {
            buffer[i] = view[i];
        }
        return buffer;
    }


    const inputChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.updateForm(
            e.target.name, parseInt(e.target.value),
        )
    }

    const selectOptionClick = (name: string, value: any) => {
        props.updateForm(name, value)
    }


    

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    return (
        <form className="mb-3" method="POST" onSubmit={props.submit}>
            {props.fields.map((field, index) => {
                if (field.name === "pass" && field.param) {
                    //добавляет над полем "забыли пароль?"
                    return (
                        <div className="mb-3 form-password-toggle" key={"form_field_" + index}>
                            <div className="d-flex justify-content-between">
                                <label className="form-label" htmlFor="password">Пароль</label>
                                <Link to="/forgot">
                                    <small>Забыли пароль?</small>
                                </Link>
                            </div>
                            <div className="input-group input-group-merge">
                                <input
                                    type={field.type}
                                    className="form-control"
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    autoFocus={field.autofocus}
                                    onChange={inputChange}
                                />
                                <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                            </div>
                        </div>
                    )
                }
                switch (field.type) {
                    case FiledType.Select:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <select value={field.value} onChange={(e) => selectOptionClick(field.name, e.target.value)} name={field.name} className="form-select mb-3" key={"form_field_" + index}>
                                    <option disabled selected value="">{field.placeholder}</option>
                                    {field.options?.map((option) => {
                                        return <option value={option.value}>{option.title}</option>
                                    })}
                                </select>
                            </div>
                        )
                    case FiledType.MultiSelect:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <select multiple onChange={(e) => { let value = Array.from(e.target.selectedOptions, option => option.value); selectOptionClick(field.name, value) }} name={field.name} className="form-select mb-3" key={"form_field_" + index}>
                                    {field.options?.map((option) => {
                                        return <option value={option.value}>{option.title}</option>
                                    })}
                                </select>
                            </div>
                        )
                    case FiledType.SelectInt:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <select value={field.value} onChange={(e) => selectOptionClick(field.name, parseInt(e.target.value))} name={field.name} className="form-select mb-3" key={"form_field_" + index}>
                                    <option disabled selected value="0">{field.placeholder}</option>
                                    {field.options?.map((option) => {
                                        return <option value={option.value}>{option.title}</option>
                                    })}
                                </select>
                            </div>
                        )
                    case FiledType.TextArea:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <textarea
                                    className="form-control"
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    autoFocus={field.autofocus}
                                    onChange={inputChange}
                                />
                            </div>
                        )
                    case FiledType.Date:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <input
                                    type={field.type}
                                    className="form-control"
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    autoFocus={field.autofocus}
                                    value={formatDate(field.value)}
                                    onChange={inputDateChange}
                                />
                            </div>
                        )
                    case FiledType.File:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <input
                                    type={field.type}
                                    className={field.bytes ? "form-control bytes" : "form-control"}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    autoFocus={field.autofocus}
                                    onChange={fileChange}
                                    accept={field.accept}
                                />
                            </div>
                        )
                    case FiledType.SelectList:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span className="form-label">{field.title}</span>
                                    <button className="btn btn-primary d-grid" style={{ marginTop: "10px" }} onClick={(e) => {
                                        e.preventDefault();
                                        field.callbackAdd!();
                                    }}>+ Новый вопрос</button>
                                </div>
                                {field.value && Array.from(Array(field.value.length).keys()).map((_, ind) => {
                                    return <div style={{ display: "flex", alignItems: "end", justifyContent: "end", marginTop: "10px" }}>
                                        <span style={{ marginRight: "10px" }}>{ind + 1}. </span>
                                        <select value={field.value[ind]} onChange={(e) => inputChangeIndex(ind, field.name, field.value, e.target.value)} name={field.name} className="form-select" key={"form_field_" + index}>
                                            <option disabled selected value="">{field.placeholder}</option>
                                            {field.options?.map((option) => {
                                                return <option value={option.value}>{option.title}</option>
                                            })}
                                        </select>
                                        <Trash color="red" onClick={() => {
                                            deleteIndex(ind, field.name, field.value)
                                        }} />
                                    </div>
                                })}
                                <button className="btn btn-primary d-grid" style={{ marginTop: "10px" }} onClick={(e) => {
                                    e.preventDefault();
                                    let newValue = field.value
                                    newValue.push("")
                                    props.updateForm(field.name, newValue)
                                }}>+</button>
                            </div>
                        )
                    case FiledType.StringsList:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                {field.value && Array.from(Array(field.value.length).keys()).map((_, ind) => {
                                    return <div style={{ display: "flex", alignItems: "end", justifyContent: "end", marginTop: "10px" }}>
                                        <span style={{ marginRight: "10px" }}>{ind + 1}. </span>
                                        <input
                                            key={"form_field_" + index + "_" + ind}
                                            type={field.type}
                                            className={"form-control"}
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            autoFocus={field.autofocus}
                                            value={field.value[ind]}
                                            onChange={(e) => inputChangeIndex(ind, e.target.name, field.value, e.target.value)}
                                        />
                                        <Trash color="red" onClick={() => {
                                            deleteIndex(ind, field.name, field.value)
                                        }} />
                                    </div>
                                })
                                }
                                <button className="btn btn-primary d-grid" style={{ marginTop: "10px" }} onClick={(e) => {
                                    e.preventDefault();
                                    let newValue = field.value
                                    newValue.push("")
                                    props.updateForm(field.name, newValue)
                                }}>Добавить</button>
                            </div>
                        )
                    case FiledType.Color:
                        return (
                            <div key={"form_field_" + index} className="col-md-6 mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <input
                                    type={field.type}
                                    className="form-control"
                                    name={field.name}
                                    value={field.value}
                                    placeholder={field.placeholder}
                                    autoFocus={field.autofocus}
                                    onChange={inputChange}
                                />
                            </div>
                        )
                    default:
                        return (
                            <div key={"form_field_" + index} className="mb-3">
                                <label htmlFor={field.name} className="form-label">{field.title}</label>
                                <input
                                    type={field.type}
                                    className="form-control"
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    autoFocus={field.autofocus}
                                    value={field.value}
                                    onChange={field.type == FiledType.Number ? inputChangeNumber : inputChange}
                                />
                            </div>
                        )
                }

            })}

            {props.politics ? <div className="mb-3" key="politics">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="terms-conditions" onChange={inputChange} name="terms" />
                    <label className="form-check-label" htmlFor="terms-conditions">
                        Я согласен с <a href="#">политикой конфиденциальности</a>
                    </label>
                </div>
            </div> : <></>}
            <div className="d-flex justify-content-end">
                {props.submitBtnTitle ? <button key="submit" className={props.btnSmall == true ? "btn btn-primary d-grid" : "btn btn-primary d-grid w-100"}>{props.state.loading ? "loading" : props.submitBtnTitle}</button> : <></>}
            </div>
        </form>
    );

}
