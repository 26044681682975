import { AnketsRepository } from "../repository/api/anket/anket"
import { Auth } from "../repository/api/auth/auth"
import { InternsRepository } from "../repository/api/intern/intern"
import { MentorsRepository } from "../repository/api/mentor/mentor"
import { QuestionsRepository } from "../repository/api/question/question"
import { ReviewsRepository } from "../repository/api/review/review"
import { AnketUseCases } from "../useCase/anket/anktet"
import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { InternUseCases } from "../useCase/intern/intern"
import { MentorUseCases } from "../useCase/mentor/mentor"
import { QuestionUseCases } from "../useCase/question/question"
import { ReviewUseCases } from "../useCase/review/review"


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)

    const anketUseCase = new AnketUseCases(new AnketsRepository(), actions)
    const mentorUseCase = new MentorUseCases(new MentorsRepository(), actions)
    const questionUseCase = new QuestionUseCases(new QuestionsRepository(), actions)
    const reviewUseCase = new ReviewUseCases(new ReviewsRepository(), actions)
    const internUseCase = new InternUseCases(new InternsRepository(), actions)

    return {authUseCase, anketUseCase, mentorUseCase, questionUseCase, reviewUseCase, internUseCase}
}
