export enum AnketActionTypes {
    ANKET_REQUEST_SEND = 'anket_request_send',
    ANKET_ERROR = 'anket_error',
    ANKET_SUCCESS = 'anket_success',
    
    ANKET_CREATE_REQUEST = 'anket_create_request',
    ANKET_CREATE_SUCCESS = 'anket_create_success',
    ANKET_CREATE_ERROR = 'anket_create_error',

    ANKET_DELETE_REQUEST = 'anket_delete_request',
    ANKET_DELETE_SUCCESS = 'anket_delete_success',
    ANKET_DELETE_ERROR = 'anket_delete_error',

    ANKET_UPDATE_REQUEST = 'anket_update_request',
    ANKET_UPDATE_SUCCESS = 'anket_update_success',
    ANKET_UPDATE_ERROR = 'anket_update_error',
}