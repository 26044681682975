import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
import { AnketUseCases } from '../useCase/anket/anktet';
import { MentorUseCases } from '../useCase/mentor/mentor';
import { QuestionUseCases } from '../useCase/question/question';
import { ReviewUseCases } from '../useCase/review/review';
import { InternUseCases } from '../useCase/intern/intern';

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
    anketUseCase: AnketUseCases;
    mentorUseCase: MentorUseCases;
    questionUseCase: QuestionUseCases;
    reviewUseCase: ReviewUseCases;
    internUseCase: InternUseCases;
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)
