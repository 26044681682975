import { useContext, useState } from "react";
import { Copy, DotsVertical, Edit, Eye, Phone, Trash, WorldWww } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { Anket } from "../../../../domain/anket/anket";

export const TableRow = (props: { anket: Anket, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)


    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }

    const stagesOptions = [
        {
            title: "Обучение",
            value: "study"
        },
        {
            title: "Стажировка",
            value: "internship"
        },
        {
            title: "Испытательный срок",
            value: "probation"
        },
    ]

    const pickStageName = (stage: string) => {
        let name = ""
        stagesOptions.forEach((item) => {
            if (item.value === stage) {
                name = item.title;
            }
        })
        return name
    }




    return (<tr >
        <td>{pickStageName(props.anket.Stage())}</td>
        <td>{formatDate(props.anket.CreatedAt())}</td>
        {/*<td style={{display: "flex", justifyContent: "center", alignItems: "center", justifyItems: "center"}}><button type="button" style={{backgroundColor: "#7367f0", padding: 0, paddingTop: 6, paddingBottom: 4, paddingRight: 8, paddingLeft: 8, margin: 8}} onClick={() => {  }} className="dt-button add-new btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false"><Eye size={18} /></button></td>*/}
        <td>
            <div className="dropdown">
                <button type="button" onClick={() => { setShowMenu(!showMenu) }} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu ? "dropdown-menu show" : "dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" } : { position: "absolute", marginLeft: "-40%", } : { display: "none" }}>
                    <Link className="dropdown-item d-flex" to={"/anket/edit/" + props.anket.Id()}><Edit className="me-1" size={20} /> Редактировать</Link>
                </div>
            </div>
        </td>
    </tr>)
}
