export enum ReviewActionTypes {
    REVIEW_REQUEST_SEND = 'review_request_send',
    REVIEW_ERROR = 'review_error',
    REVIEW_SUCCESS = 'review_success',
    
    REVIEW_CREATE_REQUEST = 'review_create_request',
    REVIEW_CREATE_SUCCESS = 'review_create_success',
    REVIEW_CREATE_ERROR = 'review_create_error',

    REVIEW_DELETE_REQUEST = 'review_delete_request',
    REVIEW_DELETE_SUCCESS = 'review_delete_success',
    REVIEW_DELETE_ERROR = 'review_delete_error',

    REVIEW_UPDATE_REQUEST = 'review_update_request',
    REVIEW_UPDATE_SUCCESS = 'review_update_success',
    REVIEW_UPDATE_ERROR = 'review_update_error',
}