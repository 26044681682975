import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import anketReducer from './anket'; 
import internReducer from './intern'; 
import mentorReducer from './mentor'; 

import questionReducer from './question'; 
import reviewReducer from './review'; 
import userReducer from './user';

const reducers = combineReducers({
    login: loginReducer,
    anket : anketReducer,
    intern : internReducer,
    mentor : mentorReducer,
    question : questionReducer,
    review : reviewReducer,
    user : userReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;