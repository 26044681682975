import axios from "axios";
import { Mentor } from "../../../domain/mentor/mentor";
import { Configuration, MentorApi, MentorMentorResponse, MentorMentorWithId } from "../generated";
import { MentorRepositoryInterface } from "../../../useCase/mentor/adapters/repository/interface";

export class MentorsRepository implements MentorRepositoryInterface {
    private service: MentorApi;

    constructor() {
        this.service = new MentorApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadMentors(): Promise<Mentor[] | Error> {
        try {
            let response = await this.service.apiMentorGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let mentors : Mentor[] = []
                response.data?.map((mentorApi) => {
                    let mentor = this.toDomainMentor(mentorApi);
                    mentors.push(mentor)
                })
                return mentors
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateMentor(name: string, amoUserId: number, stageAmoId: number, studyAmoId: number, isAmoId: number): Promise<Mentor | Error> {
        try {
            let response = await this.service.apiMentorPost({name: name, amo_user_id: amoUserId, stage_amo_id: stageAmoId, study_amo_id: studyAmoId, is_amo_id: isAmoId}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                let mentor = this.toDomainMentor(response.data);
                return mentor
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateMentor(id: UniqueId, name: string, amoUserId: number, stageAmoId: number, studyAmoId: number, isAmoId: number): Promise<Mentor | Error> {
        try {

            let response = await this.service.apiMentorIdPut(id, {name: name, amo_user_id: amoUserId, stage_amo_id: stageAmoId, study_amo_id: studyAmoId, is_amo_id: isAmoId}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let mentor = this.toDomainMentor(response.data);
                return mentor
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadMentorById(id: UniqueId): Promise<Mentor | Error> {
        try {

            let response = await this.service.apiMentorIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let mentor = this.toDomainMentor(response.data);
                return mentor
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeleteMentorById(id: UniqueId): Promise<string | Error> {
        try {

            let response = await this.service.apiMentorIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return id
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadMentorAmoFields(): Promise<{[key: string]: MentorMentorWithId[];} | Error> {
        try {
            let response = await this.service.apiMentorAmoGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }


    

    toDomainMentor(mentor: MentorMentorResponse): Mentor {
        return new Mentor(mentor.id!, mentor.name!, mentor.amo_user_id!, mentor.study_amo_id!, mentor.stage_amo_id!, mentor.is_amo_id!, new Date(mentor.created_at!), new Date(mentor.modified_at!));
    }
}