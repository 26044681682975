import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"

export const AnketList = () => {
    let { id } = useParams();
    const [reportTypes, setReportTypes] = useState<{ title: string, value: string }[] | null>()
    const [page, setPage] = useState(0)
    const [countOnPage, setCountOnPage] = useState(50)

    const [form, setForm] = useState<{
        offset: number,
        limit: number,
        date: Date,
        anketName: string,
    }>({
        offset: 0,
        limit: 50,
        date: new Date(),
        anketName: "",
    })


    let useCases = useContext(UseCasesContext)

    const ankets = useTypedSelector(({ anket }) => {
        return anket
    })

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let append = "T23:59:59Z"
        if (e.target.name === "dateStart") {
            append = "T00:00:00Z"
        }


        setForm({
            ...form,
            [e.target.name]: new Date(e.target.value)
        })
    }

    useEffect(() => {

        setForm({
            ...form,
            offset: countOnPage * (page),
            limit: countOnPage,
        })
    }, [page, countOnPage])

    useEffect(()=>{
        useCases?.anketUseCase.ReadAnkets()
    },[])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-xl-5 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список анкет /</span> Анкеты</h4>
                        </div>
                        
                        <div>
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <button onClick={()=>{useCases?.anketUseCase.PublishNewAnketVersion()}} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Опубликовать изменения</span></button>
                                </div>
                            </div>
                        </div>

                        <div className="card" style={{ background: "#fff", }}>
                            <div className="table-responsive text-nowrap col-xl-12">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th>Этап</th>
                                            <th>Дата изменения</th>
                                            {/*<th style={{display: "flex", justifyContent: "center", alignItems: "center", justifyItems: "center"}}>Просмотр</th>*/}
                                            <th style={{width: "10px"}}>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            ankets?.ankets && ankets.ankets.map((anket, index) => {
                                                return <TableRow key={anket.Stage() + index} anket={anket} last={ankets?.ankets!.length - 1 == index && ankets.ankets!.length > 1 ? true : false} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
