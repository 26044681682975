import axios from "axios";
import { Question } from "../../../domain/question/question";
import { Configuration, QuestionApi, QuestionQuestionResponse } from "../generated";
import { QuestionRepositoryInterface } from "../../../useCase/question/adapters/repository/interface";

export class QuestionsRepository implements QuestionRepositoryInterface {
    private service: QuestionApi;

    constructor() {
        this.service = new QuestionApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadQuestions(): Promise<Question[] | Error> {
        try {
            let response = await this.service.apiQuestionGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let questions : Question[] = []
                response.data?.map((questionApi) => {
                    let question = this.toDomainQuestion(questionApi);
                    questions.push(question)
                })
                return questions
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateQuestion(title: string, questionType: string, questionParams: Object): Promise<Question | Error> {
        try {
            let response = await this.service.apiQuestionPost({title: title, question_type: questionType, question_params: questionParams}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                let question = this.toDomainQuestion(response.data);
                return question
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateQuestion(id: UniqueId, title: string, questionType: string, questionParams: Object): Promise<Question | Error> {
        try {

            let response = await this.service.apiQuestionIdPut(id, {title: title, question_type: questionType, question_params: questionParams}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                let question = this.toDomainQuestion(response.data);
                return question
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadQuestionById(id: UniqueId): Promise<Question | Error> {
        try {

            let response = await this.service.apiQuestionIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let question = this.toDomainQuestion(response.data);
                return question
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeleteQuestionById(id: UniqueId): Promise<string | Error> {
        try {

            let response = await this.service.apiQuestionIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return id
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    

    toDomainQuestion(question: QuestionQuestionResponse): Question {
        return new Question(question.id!, question.title!, question.question_type!, question.question_params!, new Date(question.created_at!), new Date(question.modified_at!));
    }
}