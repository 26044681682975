import { Anket } from "../../domain/anket/anket";
import { AnketActionTypes } from "../action-types";
import { AnketActions } from "../actions";
import produce from 'immer';


interface AnketState {
    loading: boolean | null;
    ankets: Anket[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: AnketState = {
    loading: null,
    ankets: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: AnketState = initialState, action: AnketActions) => {
    switch (action.type) {
        case AnketActionTypes.ANKET_REQUEST_SEND:
            state.loading = true;
            return state;
        case AnketActionTypes.ANKET_SUCCESS:
            state.loading = false;
            state.ankets = action.payload;
            return state;
        case AnketActionTypes.ANKET_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case AnketActionTypes.ANKET_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case AnketActionTypes.ANKET_CREATE_SUCCESS:
            state.entityLoading = false
            state.ankets!.push(action.payload)
            state.entityError = ""
            return state;
        case AnketActionTypes.ANKET_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case AnketActionTypes.ANKET_DELETE_SUCCESS:
            state.ankets = state.ankets!.filter((ankets) => ankets.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
