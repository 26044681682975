import { Fragment, useState } from "react"

export const RangeQuestion = (props: { title: string, min: number, max: number, answer: number, setAnswer: (val: number) => void }) => {
    const [hovered, setHovered] = useState<number | null >(0)
    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
            <span style={{ marginBottom: "10px" }}>{props.title} {props.answer ? <>({props.answer} / {props.max})</> : <></>}</span>
            <div className="basic-ratings jq-ry-container" style={{ width: "192px;" }}>
                <div className="jq-ry-group-wrapper">
                    <div className="jq-ry-normal-group jq-ry-group">
                        <div style={{ display: "flex", }}>
                            <div style={{ marginRight: 15, display: "flex", }}>
                                <EmptyStar selected={props.answer === null} hovered={hovered} setHovered={(val: number | null) => { setHovered(val) }} setSelected={(val: number) => { props.setAnswer(val) }} />
                                {Array.from(Array(props.max).keys()).map((_, index) => {
                                    return <Star index={index + 1} selected={props.answer && index < props.answer ? true : false} hovered={hovered} setHovered={(val: number | null) => { setHovered(val) }} setSelected={(val: number) => { props.setAnswer(val) }} />
                                })}
                            </div>
                            <span style={{ fontSize: "20px", fontWeight: "bold" }}>{hovered !== null ? hovered : props.answer ? props.answer : 0}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const EmptyStar = (props: { selected: boolean, hovered: number | null, setHovered: (val: number | null) => void, setSelected: (val: number) => void }) => {
    return (
        <div style={{ cursor: "pointer", width: "20px", height: "32px" }} onMouseEnter={() => { props.setHovered(0) }} onMouseLeave={() => { props.setHovered(null) }} onClick={() => props.setSelected(0)} >
        </div>
    )
}

export const Star = (props: { index: number, selected: boolean, hovered: number | null, setHovered: (val: number | null) => void, setSelected: (val: number) => void }) => {
    const getColor = () => {
        if (props.hovered !== null) {
            if (props.index <= props.hovered!) {
                return "#ff9f43"
            } else {
                return "#dfdfe3"
            }
        } else {
            if (props.selected) {
                return "#ff9f43"
            } else {
                return "#dfdfe3"
            }
        }
    }
    return (
        <svg style={{ cursor: "pointer" }} onMouseEnter={() => { props.setHovered(props.index) }} onMouseLeave={() => { props.setHovered(null) }} onClick={() => props.setSelected(props.index)} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 12.705 512 486.59" x="0px" y="0px" xmlSpace="preserve" width="32px" height="32px" fill={getColor()}>
            <polygon points="256.814,12.705 317.205,198.566 512.631,198.566 354.529,313.435 414.918,499.295 256.814,384.427 98.713,499.295 159.102,313.435 1,198.566 196.426,198.566 ">
            </polygon>
        </svg>
    )
}