import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { Menu } from "../../../components/menu"

export const InternsForAssign = () => {
    let { id } = useParams();

    const [form, setForm] = useState<{
    }>({
    })


    let useCases = useContext(UseCasesContext)

    const interns = useTypedSelector(({ intern }) => {
        return intern
    })

    useEffect(() => {
        useCases?.internUseCase.ReadInternsForAssign()
    }, [])



    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-xl-5 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список стажеров для распределения /</span> Стажеры</h4>
                        </div>

                        <div className="card" style={{ background: "#fff", }}>
                            <div className="table-responsive text-nowrap col-xl-12">
                                <div className="list-group list-group-flush">
                                    {interns && interns.interns?.map((intern, index)=>{
                                        return <Link to={"/interns/"+intern.Id()} className="list-group-item list-group-item-action" style={{padding: "20px"}}>{index+1}. {intern.Name()}</Link>
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
