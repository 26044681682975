export enum QuestionActionTypes {
    QUESTION_REQUEST_SEND = 'question_request_send',
    QUESTION_ERROR = 'question_error',
    QUESTION_SUCCESS = 'question_success',
    
    QUESTION_CREATE_REQUEST = 'question_create_request',
    QUESTION_CREATE_SUCCESS = 'question_create_success',
    QUESTION_CREATE_ERROR = 'question_create_error',

    QUESTION_DELETE_REQUEST = 'question_delete_request',
    QUESTION_DELETE_SUCCESS = 'question_delete_success',
    QUESTION_DELETE_ERROR = 'question_delete_error',

    QUESTION_UPDATE_REQUEST = 'question_update_request',
    QUESTION_UPDATE_SUCCESS = 'question_update_success',
    QUESTION_UPDATE_ERROR = 'question_update_error',
}