import { AnketsRepository } from "../../repository/api/anket/anket";
import { actionCreators } from "../../state";

export class AnketUseCases {
    private api: AnketsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: AnketsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadAnkets = async () => {
        this.stateActions.SendAnketListRequest()
        let response = await this.api.ReadAnkets()
        if (response instanceof Error) {
            this.stateActions.AnketListError(response.message)
            return
        } else {
            this.stateActions.AnketListSuccess(response)
        }
    }

    public DeleteAnket = async (id: string) => {
        this.stateActions.AnketDeleteRequest()
        let response = await this.api.DeleteAnketById(id)
        if (response instanceof Error) {
            this.stateActions.AnketDeleteError(response.message)
            return
        } else {
            this.stateActions.AnketDeleteSuccess(id)
        }
    }

    public ReadAnketById = async (id: UniqueId) => {
        let response = await this.api.ReadAnketById(id)
        return response
    }

    public PublishNewAnketVersion = async () => {
        let response = await this.api.PublishNewAnketVersion()
        return response
    }

    public CreateAnket = async (questions: string[], stage: string, callback?: ()=>void) => {
        this.stateActions.AnketCreateRequest()
        let response = await this.api.CreateAnket(questions, stage)
        if (response instanceof Error) {
            this.stateActions.AnketCreateError(response.message)
            return
        } else {
            this.stateActions.AnketCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }



    public UpdateAnketQuestions = async (id: UniqueId, questions: string[], callback?: ()=>void) => {
        this.stateActions.AnketUpdateRequest()
        let response = await this.api.UpdateAnketQuestions(id, questions)
        if (response instanceof Error) {
            this.stateActions.AnketUpdateError(response.message)
            return
        } else {
            this.stateActions.AnketUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}