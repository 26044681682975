import { Dispatch } from "react"
import { MentorActionTypes } from "../action-types";
import { MentorActions } from "../actions"
import { Mentor } from "../../domain/mentor/mentor";

export const SendMentorListRequest = () => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_REQUEST_SEND,
          });
    }
}

export const MentorListSuccess = (list: Mentor[]) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_SUCCESS,
            payload: list,
        });
    }
}

export const MentorListError = (error:string) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_ERROR,
            payload: error,
        });
    }
}

export const MentorCreateRequest = () => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_CREATE_REQUEST,
          });
    }
}

export const MentorCreateSuccess = (mentor: Mentor) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_CREATE_SUCCESS,
            payload: mentor,
          });
    }
}

export const MentorCreateError = (message: string) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_CREATE_ERROR,
            payload: message,
          });
    }
}


export const MentorUpdateRequest = () => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_UPDATE_REQUEST,
          });
    }
}

export const MentorUpdateSuccess = (category: Mentor) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const MentorUpdateError = (message: string) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const MentorDeleteRequest = () => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_DELETE_REQUEST,
          });
    }
}

export const MentorDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const MentorDeleteError = (message: string) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_DELETE_ERROR,
            payload: message,
          });
    }
}