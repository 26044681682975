import axios from "axios";
import { Review } from "../../../domain/review/review";
import { AnketAnketResponse, Configuration, MentorMentorResponse, QuestionQuestionResponse, ReviewApi, ReviewReviewReportForAnketVersion } from "../generated";
import { ReviewRepositoryInterface } from "../../../useCase/review/adapters/repository/interface";
import { Mentor } from "../../../domain/mentor/mentor";
import { Anket } from "../../../domain/anket/anket";
import { Question } from "../../../domain/question/question";

export class ReviewsRepository implements ReviewRepositoryInterface {
    private service: ReviewApi;

    constructor() {
        this.service = new ReviewApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async RequestReview(leadId: number, userId: number): Promise<{mentor: Mentor, anketId: UniqueId, anketVersionId: UniqueId, questions: Question[]} | Error> {
        try {
            let response = await this.service.apiReviewLeadIdUserIdGet(leadId.toString(), userId.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let anketId = response.data?.anket_id!
                let anketVersionId = response.data.anket_version_id!
                let mentor = this.toDomainMentor(response.data?.mentor!)
                let questions:Question[] = []
                response.data.questions!.map((question) => {
                    questions.push(this.toDomainQuestion(question))
                })
                return {mentor: mentor, anketId: anketId, anketVersionId: anketVersionId, questions: questions}
            } else {

            }
        } catch(e) {
            
            let error: string = ""
            if (axios.isAxiosError(e)) {
                return Error(e.response?.data.error)
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateReview(mentorId: UniqueId, anketId: UniqueId, anketVersionId: UniqueId, leadId: number, answers: object): Promise<string | Error> {
        try {
            let response = await this.service.apiReviewPost({mentor_id: mentorId, anket_id: anketId, anket_version_id: anketVersionId, lead_id: leadId, answers: answers}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "ok"
            } else {

            }
        } catch(e) {
            
            let error: string = ""
            if (axios.isAxiosError(e)) {
                return Error(e.response?.data.error)
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadReport(from: Date, to: Date, filter: {fio: string, isMentor: number, probationMentor: number, studyMentor: number, headOfGroup: number, isFired: any,}): Promise<ReviewReviewReportForAnketVersion[] | Error> {
        try {
            let response = await this.service.apiReviewReportPost({fio: filter.fio, is_mentor: filter.isMentor, probation_mentor: filter.probationMentor, study_mentor: filter.studyMentor, head_of_group: filter.headOfGroup, is_fired: filter.isFired}, formatDate(from), formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            } else {

            }
        } catch(e) {
            
            let error: string = ""
            if (axios.isAxiosError(e)) {
                return Error(e.response?.data.error)
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadReportXlsx(from: Date, to: Date): Promise<File | Error> {
        try {
            let response = await this.service.apiReviewReportXlsxPost(formatDate(from), formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            } else {

            }
        } catch(e) {
            
            let error: string = ""
            if (axios.isAxiosError(e)) {
                return Error(e.response?.data.error)
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    toDomainAnket(anket: AnketAnketResponse): Anket {
        return new Anket(anket.id!, anket.stage!, anket.questions!, new Date(anket.created_at!), new Date(anket.modified_at!));
    }

    toDomainMentor(mentor: MentorMentorResponse): Mentor {
        return new Mentor(mentor.id!, mentor.name!, mentor.amo_user_id!, mentor.study_amo_id!, mentor.stage_amo_id!, mentor.is_amo_id!, new Date(mentor.created_at!), new Date(mentor.modified_at!));
    }

    toDomainQuestion(question: QuestionQuestionResponse): Question {
        return new Question(question.id!, question.title!, question.question_type!, question.question_params!, new Date(question.created_at!), new Date(question.modified_at!));
    }
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}